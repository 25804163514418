import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledCol } from '@/shared/styles';
import { StyledLandingText } from '../../../LandingPages.styles';
import { StyledBorderedCard } from '../Cards.styles';
import { InfoCardProps } from '../Cards.consts';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '@/shared/themes/darkTheme';
import { StyledMainTitle } from '@/Views/BeforeLogin/BeforeLogin.styles';

export const BorderedInfoCard: React.FC<InfoCardProps> = ({
  $title,
  $content,
  $img,
}: InfoCardProps) => {
  const { isMobile } = useDeviceDetect();

  return (
    <ThemeProvider theme={darkTheme}>
      <StyledBorderedCard>
        <StyledCol $gap={isMobile ? 16 : 20}>
          <StyledMainTitle $visualSizeLevel={isMobile ? 4 : 2} level={2}>
            {$title}
          </StyledMainTitle>
          <StyledLandingText>{$content}</StyledLandingText>
        </StyledCol>
        {$img}
      </StyledBorderedCard>
    </ThemeProvider>
  );
};
