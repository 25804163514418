import { StyledCol, StyledTitle } from '@/shared/styles';
import { PolicySection } from '@/shared/pages/Policies/PolicySection/PolicySection';
import { PolicyContent } from '@/shared/pages/Policies/PolicySection/PolicySection.consts';
import { TabsContentProps } from '../../TabsInfo.consts';

export const ComplianceInfo: React.FC<TabsContentProps> = ({ info }: TabsContentProps) => {
  return (
    <StyledCol $gap={40}>
      {info.title && <StyledTitle level={2}>{info.title}</StyledTitle>}
      {info.content?.map((comp: PolicyContent, i: number) => (
        <StyledCol key={i} $gap={20}>
          <PolicySection title={comp.title} titleLevel={3} content={comp.sections} />
        </StyledCol>
      ))}
    </StyledCol>
  );
};
