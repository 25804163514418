import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { U1Button, U1Carousel } from '@u1/react-a11y-hooks';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { getTrustedCompaneis } from './Trusted.consts';
import {
  StyledCarousel,
  StyledPlayBtnsContainer,
  StyledSlide,
  StyledSlider,
} from './LogoSlider.styles';
import { Icon } from '@/shared/components/Icon';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import { THEME_ICONS } from '@/shared/consts';
import { LogoSliderProps } from './Trusted.consts';
import { ScreenBreakpoint } from '@/hooks/WindowDimensions/WindowDimensions.consts';

export const LogoSlider: React.FC<LogoSliderProps> = ({
  $iconsColor,
  $isStretched,
}: LogoSliderProps) => {
  const [slides, setSlides] = useState<any[]>([]);
  const [autoPlay, setAutoPlay] = useState(true);
  const themeStore = useThemeStore();
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();

  useEffect(() => {
    const theme = $iconsColor ?? themeStore.isDarkMode ? THEME_ICONS.WHITE : THEME_ICONS.DARK;
    const trustedCompanies = getTrustedCompaneis(theme);
    setSlides(trustedCompanies);
  }, [$iconsColor, themeStore.isDarkMode]);

  const settings = {
    className: 'center',
    infinite: true,
    autoplaySpeed: 3000,
    speed: 2000,
    draggable: true,
    slidesToShow: 7,
    slidesToScroll: 7,
    dots: !isMobile,
    responsive: [
      {
        breakpoint: ScreenBreakpoint.WIDE_DESKTOP,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: ScreenBreakpoint.TABLET,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: ScreenBreakpoint.NARROW_TABLET,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: ScreenBreakpoint.MOBILE,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '80px',
        },
      },
    ],
  };

  return (
    <U1Carousel>
      <StyledSlider $isStretched={$isStretched}>
        <StyledCarousel autoplay={autoPlay} {...settings}>
          {slides.map((slide, index) => (
            <StyledSlide key={`slide_${index}`}>
              <Icon $src={slide.src} $alt={slide.alt} $loading="lazy" />
            </StyledSlide>
          ))}
        </StyledCarousel>
        {!isMobile && (
          <StyledPlayBtnsContainer>
            {autoPlay ? (
              <U1Button>
                <PauseOutlined
                  onClick={() => setAutoPlay(false)}
                  aria-label={t('landing_page.trusted.slider.pause')}
                  data-u1-button="button"
                />
              </U1Button>
            ) : (
              <U1Button>
                <CaretRightOutlined
                  onClick={() => setAutoPlay(true)}
                  aria-label={t('landing_page.trusted.slider.play')}
                  data-u1-button="button"
                />
              </U1Button>
            )}
          </StyledPlayBtnsContainer>
        )}
      </StyledSlider>
    </U1Carousel>
  );
};
