import { StyledTab } from '../TabsInfo.styles';
import { ComplianceInfo } from './ComplianceInfo/ComplianceInfo';
import ada from './ComplianceInfo/ada.json';
import aoda from './ComplianceInfo/aoda.json';
import atag from './ComplianceInfo/atag.json';
import coppa from './ComplianceInfo/coppa.json';
import cvaa from './ComplianceInfo/cvaa.json';
import eaa from './ComplianceInfo/eaa.json';
import en from './ComplianceInfo/en.json';
import ferpa from './ComplianceInfo/ferpa.json';
import gdpr from './ComplianceInfo/gdpr.json';
import hipaa from './ComplianceInfo/hipaa.json';
import wcag from './ComplianceInfo/wcag.json';
import s508 from './ComplianceInfo/s508.json';

export const compliances = [
  {
    key: 'ada',
    name: ada.name,
    label: <StyledTab u1-exclude="true">{ada.name}</StyledTab>,
    children: <ComplianceInfo info={ada} />,
    to: '/compliance/ada',
  },
  {
    key: 'wcag',
    name: wcag.name,
    label: <StyledTab u1-exclude="true">{wcag.name}</StyledTab>,
    children: <ComplianceInfo info={wcag} />,
    to: '/compliance/wcag',
  },
  {
    key: 's-508',
    name: s508.name,
    label: <StyledTab u1-exclude="true">{s508.name}</StyledTab>,
    children: <ComplianceInfo info={s508} />,
    to: '/compliance/s-508',
  },
  {
    key: 'en',
    name: en.name,
    label: <StyledTab u1-exclude="true">{en.name}</StyledTab>,
    children: <ComplianceInfo info={en} />,
    to: '/compliance/en',
  },
  {
    key: 'aoda',
    name: aoda.name,
    label: <StyledTab u1-exclude="true">{aoda.name}</StyledTab>,
    children: <ComplianceInfo info={aoda} />,
    to: '/compliance/aoda',
  },
  {
    key: 'hipaa',
    name: hipaa.name,
    label: <StyledTab u1-exclude="true">{hipaa.name}</StyledTab>,
    children: <ComplianceInfo info={hipaa} />,
    to: '/compliance/hipaa',
  },
  {
    key: 'gdpr',
    name: gdpr.name,
    label: <StyledTab u1-exclude="true">{gdpr.name}</StyledTab>,
    children: <ComplianceInfo info={gdpr} />,
    to: '/compliance/gdpr',
  },
  {
    key: 'coppa',
    name: coppa.name,
    label: <StyledTab u1-exclude="true">{coppa.name}</StyledTab>,
    children: <ComplianceInfo info={coppa} />,
    to: '/compliance/coppa',
  },
  {
    key: 'ferpa',
    name: ferpa.name,
    label: <StyledTab u1-exclude="true">{ferpa.name}</StyledTab>,
    children: <ComplianceInfo info={ferpa} />,
    to: '/compliance/ferpa',
  },
  {
    key: 'atag',
    name: atag.name,
    label: <StyledTab u1-exclude="true">{atag.name}</StyledTab>,
    children: <ComplianceInfo info={atag} />,
    to: '/compliance/atag',
  },
  {
    key: 'cvaa',
    name: cvaa.name,
    label: <StyledTab u1-exclude="true">{cvaa.name}</StyledTab>,
    children: <ComplianceInfo info={cvaa} />,
    to: '/compliance/cvaa',
  },
  {
    key: 'eaa',
    name: eaa.name,
    label: <StyledTab u1-exclude="true">{eaa.name}</StyledTab>,
    children: <ComplianceInfo info={eaa} />,
    to: '/compliance/eaa',
  },
];
