import React from 'react';
import type { MenuProps } from 'antd';
import { SideMenu } from './SideMenu';
import { PAGES, SIDEMENU_NAVIGATION } from '@/shared/consts';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const AdminSideMenu: React.FC = () => {
  const { navigate } = useU1Navigation();

  const onClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case SIDEMENU_NAVIGATION.ADMINS:
        navigate(PAGES.AFTER_LOGIN.ADMINS);
        break;
      case SIDEMENU_NAVIGATION.VENDORS:
        navigate(PAGES.AFTER_LOGIN.VENDORS);
        break;
      case SIDEMENU_NAVIGATION.DOCS:
        navigate(PAGES.AFTER_LOGIN.ADMIN_DOCS);
        break;
    }
  };

  return <SideMenu onClick={(e) => onClick(e)} defaultPage={SIDEMENU_NAVIGATION.DOCS} />;
};
