import { StyledCol } from '@/shared/styles';
import { LandingPageHeader } from './Common/Header/Header';
import { StyledLandingPage } from './LandingPages.styles';
import { LandingPageProps } from './LandingPages.consts';
import { Footer } from '../Footer/Footer';

export const LandingPageTemplate: React.FC<LandingPageProps> = ({
  children,
  $allowGaps = true,
}: LandingPageProps) => {
  return (
    <StyledCol>
      <LandingPageHeader />
      <StyledLandingPage $allowGaps={$allowGaps}>{children}</StyledLandingPage>
      <Footer />
    </StyledCol>
  );
};
