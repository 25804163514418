import React, { useEffect } from 'react';
import { Form } from 'antd';
import { StyledForm, StyledLabel } from '../Forms.styles';
import { StyledCol, StyledInput } from '@/shared/styles';
import { U1Button } from '../../Button/U1Button';
import { emailExp } from '@/shared/regex';
import { WhiteBorderButton } from '../../Button/WhiteBorderButton';
import { backArrow } from '@/assets/images/icons';
import { useAxios } from '@/services/apiClient';
import { SignInFormProps } from '../Forms.consts';
import { SIGN_IN_VIEW } from '@/Views/BeforeLogin/SignIn/SignIn.consts';
import { RES_STATUS } from '@/shared/consts';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect';

export const ForgotPasswordForm: React.FC<SignInFormProps> = ({
  userType,
  setView,
}: SignInFormProps) => {
  const { t } = useTranslation();
  const [{ response }, postResetPwd] = useAxios({
    url: 'passwords/send-reset-email',
    method: 'post',
  });

  const onFinish = (values: any) => {
    postResetPwd({ data: { email: values.email, userType: userType?.toUpperCase() } });
  };

  useEffect(() => {
    if (response?.status === RES_STATUS.CREATED) {
      setView(SIGN_IN_VIEW.EMAIL_SENT);
    }
  }, [response]);

  return (
    <StyledForm $marginTop={'20px'} name="basic" onFinish={onFinish} autoComplete="off">
      <StyledCol>
        <StyledLabel>{t('form.email')}</StyledLabel>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: `${t('form.email')} ${t('form.required')}`,
              pattern: emailExp,
            },
          ]}
        >
          <StyledInput
            $width={isMobileOnly ? '100%' : '440px'}
            placeholder={t('form.placeholder.email')}
          />
        </Form.Item>
      </StyledCol>
      <Form.Item>
        <StyledCol $gap={16}>
          <U1Button text={t('form.reset_password')} htmlType="submit" />
          <WhiteBorderButton
            onClick={() => setView(SIGN_IN_VIEW.MAIN)}
            $alt="back"
            $icon={backArrow}
            text={t('form.back_to_sign_in')}
            $width="100%"
          />
        </StyledCol>
      </Form.Item>
    </StyledForm>
  );
};
