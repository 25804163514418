import { useTranslation } from 'react-i18next';
import { StyledRowOffset } from '@/shared/styles';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { FooterSections } from './FooterSections/FooterSections';
import { User1stDetails } from './User1stDetails';
import {
  StyledDivider,
  StyledFooterContent,
  StyledTabletAdaptiveRow,
  StyledLandingPageFooter,
  StyledLandingPageFooterContent,
  StyledFooterContainer,
  StyledPolicies,
} from './Footer.styles';
import { getStatements } from './Footer.consts';
import { v4 as uuidv4 } from 'uuid';
import { FooterLink } from './FooterSections/FooterLink';

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isWideDesktop } = useDeviceDetect();

  return (
    <StyledLandingPageFooter>
      <StyledFooterContainer $size={'lg'}>
        <StyledLandingPageFooterContent>
          <StyledTabletAdaptiveRow $gap={isMobile ? 60 : 32} $width="100%">
            {!isWideDesktop && <User1stDetails />}
            <FooterSections />
          </StyledTabletAdaptiveRow>
          <StyledDivider />
          <StyledPolicies>
            {getStatements().map((statement) => (
              <FooterLink key={uuidv4()} to={statement.path} name={t(statement.name)} />
            ))}
          </StyledPolicies>
          <StyledRowOffset>
            <StyledFooterContent>
              {t('landing_page.footer.all_rights_reserved')}
            </StyledFooterContent>
            <StyledFooterContent>{t('landing_page.footer.copyright')}</StyledFooterContent>
          </StyledRowOffset>
        </StyledLandingPageFooterContent>
      </StyledFooterContainer>
    </StyledLandingPageFooter>
  );
};
