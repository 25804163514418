import { Icon } from '@/shared/components/Icon';
import { mobilePlatformsImages } from '../../Footer.consts';
import { StyledMobileIcons } from '../../Footer.styles';

export const MobileIcons: React.FC = () => {
  return (
    <StyledMobileIcons>
      {mobilePlatformsImages.map((img, i) => (
        <Icon key={`platform${i}`} $src={img.src} $alt={img.alt} $size={32} />
      ))}
    </StyledMobileIcons>
  );
};
