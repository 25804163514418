import { StyledCol } from '@/shared/styles';
import { certifications } from '../../Footer.consts';
import { v4 as uuidv4 } from 'uuid';
import { U1Link } from '@/shared/components/Link/U1Link';

export const Certifications: React.FC = () => {
  return (
    <StyledCol $gap={16}>
      {certifications.map((cert) => (
        <U1Link key={uuidv4()} text={cert.name} href={cert.to} shouldOpenTab={true} />
      ))}
    </StyledCol>
  );
};
