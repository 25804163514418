import styled from 'styled-components';
import { StyledCol } from '@/shared/styles';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import useDeviceDetect from '@/hooks/useDeviceDetect';

export const StyledAdaptiveInstitutions = styled.div`
  display: grid;
  gap: 24px;
  justify-content: center;

  ${() => {
    const { isMobile, isTablet, isDesktop } = useDeviceDetect();

    return isMobile
      ? `
        grid-template-columns: repeat(2, auto);
      `
      : isTablet || isDesktop
      ? `
        grid-template-columns: repeat(3, auto); 
      `
      : ` 
          display: flex;
          flex-direction: row;
          justify-content: space-between; 
          align-items: center;
          align-content: center;
          gap: 20px;
      `;
  }}
`;

export const StyledInstiturionsCol = styled(StyledCol)`
  ${() => {
    const { isDesktop, isWideDesktop } = useDeviceDetect();

    return (isDesktop || isWideDesktop) && `width: calc(50% - 20px);`;
  }}
`;

export const StyledInstitutionBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${() => {
    const { isMobile } = useDeviceDetect();

    return `
      width: ${isMobile ? '160px' : '190px'};
      height: ${isMobile ? '160px' : '190px'};
    `;
  }}
  padding: 35px;
  border: 2px solid
    ${(props) =>
      useThemeStore.getState().isDarkMode
        ? props.theme.sameColorBorder
        : props.theme.landingPageBorder};
  background: ${(props) => props.theme.sameColorBox};
  border-radius: 15px;

  img {
    width: 90px;
    height: 90px;
    object-fit: contain;
  }
`;
