import { ContactUsModal } from '@/shared/components/Modals/ContactUsModal/ContactUsModal';
import { getAboutSections } from '../../Footer.consts';
import { StyledFooterTrigger, StyledLinksSection } from '../../Footer.styles';
import { FooterLink } from '../FooterLink';

export const About: React.FC = () => {
  return (
    <StyledLinksSection>
      {getAboutSections().map((about, i) =>
        about.to ? (
          <FooterLink key={`${about.name}${i + 1}`} name={about.name} to={about.to} />
        ) : (
          <ContactUsModal
            key={`${about.name}${i + 1}`}
            refName={`contact-us-footer${i + 1}`}
            trigger={
              <StyledFooterTrigger
                data-a11y-dialog="trigger"
                data-a11y-ref={`contact-us-footer${i + 1}`}
              >
                {about.name}
              </StyledFooterTrigger>
            }
          />
        )
      )}
    </StyledLinksSection>
  );
};
