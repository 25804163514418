import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledAdjustableRow, StyledCol } from '@/shared/styles';
import { ElongatedInfoCard } from '../../../Common/Cards/ElongatedInfoCard/ElongatedInfoCard';
import {
  StyledLandingContainer,
  StyledLandingPageSection,
  StyledLandingText,
  StyledLandingPurpleTitle,
} from '../../../LandingPages.styles';
import { benefitsImages } from './Benefits.consts';

export const BenefitsOfComplience: React.FC = () => {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();
  return (
    <StyledLandingPageSection>
      <StyledLandingContainer $size={'md'}>
        <StyledCol $gap={32}>
          <StyledLandingPurpleTitle $visualSizeLevel={isMobile ? 4 : 2} level={2} $align="center">
            {t('landing_page.eaa.benefits.title')}
          </StyledLandingPurpleTitle>
          <StyledAdjustableRow $gap={20} $wrap="wrap">
            {benefitsImages.map((img, i) => (
              <ElongatedInfoCard
                key={`benefits-card${i}`}
                $title={t(`landing_page.eaa.benefits.card${i + 1}.title`)}
                $content={t(`landing_page.eaa.benefits.card${i + 1}.content`)}
                $img={img}
              />
            ))}
          </StyledAdjustableRow>
          <StyledLandingText align="center">
            {t('landing_page.eaa.benefits.content')}
          </StyledLandingText>
        </StyledCol>
      </StyledLandingContainer>
    </StyledLandingPageSection>
  );
};
