import React from 'react';
import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import longTermImg from '@/assets/images/landingPage/enterprise/longTermImg.png';
import { Image } from '@/shared/components/Image/Image';
import { StyledCol } from '@/shared/styles';
import { StyledLongTermRow } from './LongTerm.styles';
import {
  StyledLandingContainer,
  StyledLandingPageSection,
  StyledLandingPurpleTitle,
  StyledLandingText,
} from '../../../LandingPages.styles';

export const LongTerm: React.FC = () => {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();
  const textAlign = 'start';

  return (
    <StyledLandingPageSection>
      <StyledLandingContainer $size={'md'}>
        <StyledLongTermRow $gap={40}>
          <Image $src={longTermImg} $width="100%" />

          <StyledCol $gap={16} $justify="center">
            <StyledLandingPurpleTitle
              $visualSizeLevel={isMobile ? 4 : 2}
              level={2}
              $align={textAlign}
            >
              {t('landing_page.enterprise.long_term.title')}
            </StyledLandingPurpleTitle>
            <StyledLandingText fontSize={'18px'} textAlign={textAlign}>
              {t('landing_page.enterprise.long_term.content1')}
            </StyledLandingText>
            <StyledLandingText fontSize={'18px'} textAlign={textAlign}>
              {t('landing_page.enterprise.long_term.content2')}
            </StyledLandingText>
          </StyledCol>
        </StyledLongTermRow>
      </StyledLandingContainer>
    </StyledLandingPageSection>
  );
};
