import useDeviceDetect from '@/hooks/useDeviceDetect';
import introImgMobile from '@/assets/images/landingPage/introImgMobile.png';
import introImage from '@/assets/images/landingPage/introImg.png';
import { Image } from '@/shared/components/Image/Image';
import { IntroContent } from './IntroContent';
import { StyledFakeIntroImg, StyledIntroContent, StyledMainIntro } from './Intro.styles';
import { StyledLandingContainer, StyledLandingPageSection } from '../../../LandingPages.styles';

export const Intro: React.FC = () => {
  const { isMobile, isTablet, isWideDesktop } = useDeviceDetect();

  return (
    <StyledLandingPageSection $marginBottom={isWideDesktop ? 0 : -40}>
      <StyledLandingContainer $size={'md'}>
        <StyledMainIntro $gap={isMobile ? 40 : 24}>
          <StyledIntroContent>
            <IntroContent />
          </StyledIntroContent>
          {(isMobile || isTablet) && <Image $src={introImgMobile} />}
          {!isMobile && !isTablet && (
            <>
              {/* Original image is placed on absolute so we need a fake one to prevent content overlapping */}
              <StyledFakeIntroImg />
              <Image $src={introImage} />
            </>
          )}
        </StyledMainIntro>
      </StyledLandingContainer>
    </StyledLandingPageSection>
  );
};
