import { useAuthStore } from '@/stores/AuthStore';
import { COOKIES, PAGES, RES_STATUS, USER_TYPE } from '@/shared/consts';
import { useEffect, useState } from 'react';
import { useSessionStorage } from './Storage/useSessionStorage';
import { useAxios } from '@/services/apiClient';
import { useUserStore } from '@/stores/UserStore';
import { useSubscription } from './useSubscription';
import { LOGIN_STATE } from '@/stores/Stores.consts';
import { TokenDto } from '@/shared/dto';
import { useU1Navigation } from './useU1Navigation';
import { removeCookie, setCookie } from '@/services/cookieService';
import { CookieType } from './AllowCookies/AllowCookies.consts';
import { useUserJourney } from './UserJourney/useUserJourney';

export const useAuth = () => {
  const authStore = useAuthStore();
  const { navigate } = useU1Navigation();
  const [pathToNavigate, setPathToNavigate] = useState('');
  const { removeStorage, getStorage } = useSessionStorage();
  const setUser = useUserStore((state) => state.setUser);
  const setAvatar = useUserStore((state) => state.setAvatar);
  const { getSubscription } = useSubscription();
  const { sendUserJourney } = useUserJourney();

  const [{ response: avatarRes }, getAvatar] = useAxios(
    {
      method: 'get',
      url: '/vendors-settings/avatars',
      responseType: 'blob',
    },
    { manual: true }
  );

  const [{ data: authData, loading: loadingAuth, error: authErr }, getUser] = useAxios(
    {
      method: 'get',
      url: '/auth/me',
    },
    { manual: true }
  );

  const [{ data: loginTokenData, error: loginAuthErr }, postLogin] = useAxios(
    {
      method: 'post',
      url: '/auth/login',
    },
    { manual: true }
  );

  useEffect(() => {
    if (loginTokenData) {
      login(loginTokenData);
    }
  }, [loginTokenData]);

  useEffect(() => {
    if (avatarRes) {
      const imageUrl = URL.createObjectURL(new Blob([avatarRes.data]));
      setAvatar(imageUrl);
    }
  }, [avatarRes]);

  useEffect(() => {
    if (authData) {
      if (authData.type === USER_TYPE.VENDOR) {
        getAvatar();
      }
      setUser(authData);
      authStore.setLoginState(LOGIN_STATE.LOGGED_IN);
      if (authData.type === USER_TYPE.CLIENT) getSubscription();
    }
  }, [authData]);

  useEffect(() => {
    if (
      authErr?.status === RES_STATUS.UNAUTHORIZED ||
      authErr?.status === RES_STATUS.FORBIDDEN ||
      loginAuthErr?.status === RES_STATUS.UNAUTHORIZED
    ) {
      removeCookie(COOKIES.ACCESS_TOKEN);
      authStore.setLoginState(LOGIN_STATE.LOGGED_OUT);
    }
  }, [authErr]);

  const logout = () => {
    removeCookie(COOKIES.ACCESS_TOKEN);
    removeStorage(COOKIES.REDIRECT_URL);
    authStore.setLoginState(LOGIN_STATE.LOGGED_OUT);
    navigate(PAGES.SHARED.DEFAULT);
  };

  useEffect(() => {
    if (getStorage(COOKIES.REDIRECT_URL)) setPathToNavigate(getStorage(COOKIES.REDIRECT_URL));
  }, []);

  const login = (token: TokenDto) => {
    setCookie(COOKIES.ACCESS_TOKEN, token.accessToken, CookieType.NECESSARY);
    sendUserJourney();
    if (token.passed2FA) {
      getUser();
      authStore.setLoginState(LOGIN_STATE.LOGGED_IN);
      navigate(pathToNavigate.length ? pathToNavigate : PAGES.SHARED.DEFAULT);
    }
  };

  return { logout, login, loadingAuth, getUser, postLogin, loginAuthErr, loginTokenData };
};
