import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { Image } from '@/shared/components/Image/Image';
import enterpriseLandingPageIntroImg from '@/assets/images/landingPage/enterprise/enterpriseLandingPageIntroImg.jpg';
import issuesDetectedPopup from '@/assets/images/landingPage/enterprise/issuesDetectedPopup.svg';
import { IntroContent } from './IntroContent';
import { TextInsideImage } from '../../../Common/TextInsideImage/TextInsideImage';
import {
  EnterpriseDesktopIntro,
  EnterpriseDesktopIntroImage,
  EnterpriseDesktopIntroLabel,
} from './intro.styles';
import { StyledLandingContainer, StyledLandingPageSection } from '../../../LandingPages.styles';

export const Intro: React.FC = () => {
  const { isMobile, isTablet } = useDeviceDetect();
  const { t } = useTranslation();

  return (
    <StyledLandingPageSection>
      <StyledLandingContainer $size={'md'}>
        <EnterpriseDesktopIntro $align={isMobile || isTablet ? 'stretch' : 'center'}>
          <IntroContent />
          <TextInsideImage
            $className="issueDetectedImage"
            $width={isMobile || isTablet ? '100%' : '610px'}
            $txt={t('landing_page.enterprise.intro.caption')}
            $imgSrc={enterpriseLandingPageIntroImg}
          >
            <>
              <EnterpriseDesktopIntroImage>
                <Image $src={enterpriseLandingPageIntroImg} />
              </EnterpriseDesktopIntroImage>
              <EnterpriseDesktopIntroLabel>
                <Image $src={issuesDetectedPopup} $alt="issues detected popup example" />
              </EnterpriseDesktopIntroLabel>
            </>
          </TextInsideImage>
        </EnterpriseDesktopIntro>
      </StyledLandingContainer>
    </StyledLandingPageSection>
  );
};
