import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { ButtonBanner } from '../../../Common/ButtonBanner/ButtonBanner';
import { DemoModal } from '../../../Common/DemoModal/DemoModal';
import {
  StyledGetStartedPurpleText,
  StyledGetStartedWhiteText,
  StyledGetStartedPurpleSubTitle,
} from './GetStarted.styles';
import {
  StyledHybridTitle,
  StyledLandingPageSection,
  StyledLandingContainer,
} from '../../../LandingPages.styles';

export const GetStarted: React.FC = () => {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();

  return (
    <StyledLandingPageSection>
      <StyledLandingContainer $size={'md'}>
        <ButtonBanner
          $title1={
            <StyledGetStartedPurpleSubTitle $visualSizeLevel={3} level={2}>
              {t('landing_page.get_started.title1')}
            </StyledGetStartedPurpleSubTitle>
          }
          $title2={
            <StyledHybridTitle $visualSizeLevel={isMobile ? 4 : 3} level={3} $align="center">
              <StyledGetStartedWhiteText>
                {t('landing_page.get_started.title2.solutions')}{' '}
              </StyledGetStartedWhiteText>
              <StyledGetStartedPurpleText>
                {t('landing_page.get_started.title2.make_accessible')}
              </StyledGetStartedPurpleText>
            </StyledHybridTitle>
          }
          $content={t('landing_page.get_started.content')}
          $button={
            <DemoModal
              $btnText={isMobile ? t('landing_page.request_demo') : t('landing_page.schedule_demo')}
              $refName="contact-us-get-started"
              $btnWidth={isMobile ? '100%' : 'fit-content'}
            />
          }
          $dark={true}
        />
      </StyledLandingContainer>
    </StyledLandingPageSection>
  );
};
