import { ErrorIcon } from '@/assets/images/icons';
import React from 'react';
import { Icon } from '../Icon';
import { FromErrorProps } from './Forms.consts';
import { StyledErrorIcon, StyledFormError } from './Forms.styles';
import { capitalizeFirstLetter } from '@/shared/utils';

export const FormError: React.FC<FromErrorProps> = ({
  message,
  $width,
  $margin,
  $align,
  $ref,
}: FromErrorProps) => {
  return (
    <StyledFormError $width={$width} $margin={$margin} $align={$align} role="alert" ref={$ref}>
      <StyledErrorIcon>
        <Icon $src={ErrorIcon} />
      </StyledErrorIcon>
      {capitalizeFirstLetter(message)}
    </StyledFormError>
  );
};
