import styled from 'styled-components';
import { StyledAdjustableRow } from '@/shared/styles';
import useDeviceDetect from '@/hooks/useDeviceDetect';

export const StyledLongTermRow = styled(StyledAdjustableRow)`
  align-items: center;

  ${() => {
    const { isMobile, isTablet } = useDeviceDetect();

    return `
      flex-direction: ${isMobile || isTablet ? 'column' : 'row'} !important;
      img {
        order: ${isMobile || isTablet ? '1' : '0'};
        max-width: ${isMobile || isTablet ? '768px' : '610px'};
      }
    `;
  }}
`;
