import twitter from '@/assets/images/landingPage/footer/twitter.svg';
import facebook from '@/assets/images/landingPage/footer/facebook.svg';
import linkedin from '@/assets/images/landingPage/footer/linkedIn.svg';
import youtube from '@/assets/images/landingPage/footer/youtube.svg';
import ios from '@/assets/images/landingPage/footer/ios.svg';
import android from '@/assets/images/landingPage/footer/android.svg';
import react from '@/assets/images/landingPage/footer/react.svg';
import flutter from '@/assets/images/landingPage/footer/flutter.svg';
import { PAGES } from '@/shared/consts';
import { t } from 'i18next';
import { buildLangPath } from '@/shared/utils';

export const certifications = [
  {
    name: 'ISO 27001',
    to: `${import.meta.env.VITE_BASE_CERTIFICATES_URL}/certification_iso_27001.pdf`,
  },
  {
    name: 'ISO 27018',
    to: `${import.meta.env.VITE_BASE_CERTIFICATES_URL}/certification_iso_27018.pdf`,
  },
  {
    name: 'ISO 27017',
    to: `${import.meta.env.VITE_BASE_CERTIFICATES_URL}/certification_iso_27017.pdf`,
  },
  {
    name: 'ISO 27701',
    to: `${import.meta.env.VITE_BASE_CERTIFICATES_URL}/certification_iso_27701.pdf`,
  },
];

export const webPlatforms = [
  { name: 'React', to: '/web-platforms/react' },
  { name: 'Angular', to: '/web-platforms/angular' },
  { name: 'Vue', to: '/web-platforms/vue-js' },
  { name: 'JS', to: '/web-platforms/js' },
  { name: 'Shopify', to: '/web-platforms/shopify' },
  { name: 'Wordpress (+Elementor)', to: '/web-platforms/wordpress' },
  { name: 'Umbraco', to: '/web-platforms/umbraco' },
];

export const mobilePlatforms = [
  { name: 'Native Android', to: '/mobile-platforms/android' },
  { name: 'Native iOS', to: '/mobile-platforms/ios' },
  { name: 'Flutter', to: '/mobile-platforms/flutter' },
  { name: 'React Native', to: '/mobile-platforms/react-native' },
];

export const getAboutSections = () => [
  { name: t('header.menu.about_us'), to: PAGES.BEFORE_LOGIN.ABOUT_US },
  { name: t('header.menu.our_team'), to: PAGES.BEFORE_LOGIN.OUR_TEAM },
  { name: t('header.menu.pricing'), to: PAGES.BEFORE_LOGIN.PRICING },
  { name: t('header.menu.vendor'), to: PAGES.BEFORE_LOGIN.VENDOR_SIGN_IN },
  { name: t('header.menu.faq'), to: PAGES.BEFORE_LOGIN.FAQ },
  {
    name: t('landing_page.footer.about.enterprise_solution'),
    to: PAGES.BEFORE_LOGIN.ENTERPRISE_LANDING_PAGE,
  },
  {
    name: t('pricing.package.contact_us'),
  },
];

export const socialMedia = [
  { name: 'twitter', href: 'https://twitter.com/user1st', icon: twitter },
  { name: 'facebook', href: 'https://www.facebook.com/user1st/', icon: facebook },
  { name: 'linkedin', href: 'https://www.linkedin.com/company/user1st/', icon: linkedin },
  {
    name: 'youtube',
    href: 'https://www.youtube.com/@AccessibilityUser1st',
    icon: youtube,
  },
];

export const mobilePlatformsImages = [
  { src: ios, alt: 'IOS' },
  { src: android, alt: 'android' },
  { src: react, alt: 'react' },
  { src: flutter, alt: 'flutter' },
];

export type FooterContainerProps = {
  $size?: 'lg' | 'md' | 'sm' | 'full';
  $gap?: number;
};

export const getStatements = () => [
  {
    name: 'footer.accessibility_statement',
    path: buildLangPath(PAGES.SHARED.ACCESSIBILITY_STATEMENT),
  },
  {
    name: 'footer.privacy_policy',
    path: buildLangPath(PAGES.SHARED.PRIVACY_POLICY),
  },
  { name: 'footer.terms', path: buildLangPath(PAGES.SHARED.TERMS_AND_CONDITIONS) },
];
