import { PinkGradientLink } from '@/shared/components/Link/PinkGradientLink';
import { PinkGradientRouterLink } from '@/shared/components/Link/PinkGradientRouterLink';
import { boldWordsRegex, linkRegex, mailtoRegex } from '@/shared/regex';
import { StyledBold, StyledbulletList, StyledNumberedList, StyledRomanList } from '@/shared/styles';
import { PolicyList, PolicyListType } from './PolicySection.consts';

export const processTextContent = (text: string): any[] => {
  const processPart = (parts: any[]): any[] => {
    return parts.flatMap((part) => {
      if (typeof part === 'string') {
        // Apply bold words regex
        if (part.match(boldWordsRegex)) {
          return processPart(getBoldTextComp(part));
        }
        // Apply mailto regex
        if (part.match(mailtoRegex)) {
          return processPart([getMailToComp(part)]);
        }
        // Apply link regex
        if (part.match(linkRegex)) {
          return processPart(getLinkComp(part));
        }
        return part; // No match, keep as is
      }
      return part; // Already processed component
    });
  };

  // Initial processing with the input text
  return processPart([text]);
};

export const getListComp = (listEl: PolicyList) => {
  return listEl.type === PolicyListType.BULLETS
    ? StyledbulletList
    : listEl.type === PolicyListType.ROMAN
    ? StyledRomanList
    : StyledNumberedList;
};

const getLinkComp = (text: string) => {
  const textParts = text.split('{{');
  const newComp = textParts.map((part) => {
    const sections = part.split('}}');
    const linkParts = sections[0].split('|');
    return sections.length > 1 ? (
      <>
        <PinkGradientLink key={1} text={linkParts[0]} href={linkParts[1]} />
        {sections[1]}
      </>
    ) : (
      part
    );
  });
  return newComp;
};

const getMailToComp = (text: string) => {
  const email = 'support@user1st.com';
  const emailComp = <PinkGradientRouterLink text={email} href={`mailto:${email}`} />;
  const parts = text.split(mailtoRegex);
  return (
    <>
      {parts[0]}
      {emailComp}
      {parts[1]}
    </>
  );
};

const getBoldTextComp = (text: string) => {
  const parts = text.split(boldWordsRegex);
  const compWithBoldText = parts.map((part, index) =>
    part.startsWith('**') && part.endsWith('**') ? (
      <StyledBold key={index}>{part.slice(2, -2)}</StyledBold>
    ) : (
      part
    )
  );
  return compWithBoldText;
};
