import { useTranslation } from 'react-i18next';
import complianceImg from '@/assets/images/landingPage/eaa/compliance.png';
import experienceImg from '@/assets/images/landingPage/eaa/experience.png';
import {
  StyledAbsoluteImg,
  StyledComplianceAndExperience,
  StyledComplianceImg,
} from './ComplianceAndExperience.styles';
import { BorderedInfoCard } from '../../../Common/Cards/BorderedInfoCard/BorderedInfoCard';
import { StyledLandingContainer, StyledLandingPageSection } from '../../../LandingPages.styles';

export const ComplianceAndExperience: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledLandingPageSection>
      <StyledLandingContainer $size={'md'}>
        <StyledComplianceAndExperience $gap={20}>
          <BorderedInfoCard
            $title={t('landing_page.eaa.compliance.title')}
            $content={t('landing_page.eaa.compliance.content')}
            $img={<StyledComplianceImg src={complianceImg} alt="" />}
          />
          <BorderedInfoCard
            $title={t('landing_page.eaa.experience.title')}
            $content={t('landing_page.eaa.experience.content')}
            $img={<StyledAbsoluteImg src={experienceImg} alt="" />}
          />
        </StyledComplianceAndExperience>
      </StyledLandingContainer>
    </StyledLandingPageSection>
  );
};
