import styled from 'styled-components';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledAdjustableRow, StyledCol } from '@/shared/styles';
import { useLangStore } from '@/stores/LangStore/LangStore';

export const StyledOurSolution = styled(StyledCol)`
  border: 2px solid ${(props) => props.theme.landingPageBorder};
  border-radius: 16px;
  padding: 32px 16px;
  width: 100%;
  overflow: hidden;
`;

export const StyledOurSolutionRow = styled(StyledAdjustableRow)`
  ${() => {
    const { isMobile, isTablet, isWideDesktop, isDesktop } = useDeviceDetect();
    return `
      flex-direction: ${isDesktop || isWideDesktop ? 'row' : 'column'};
      align-items: ${isMobile || isTablet ? 'flex-end' : 'stretch'};
    `;
  }}
`;

export const StyledRightColImg = styled.img`
  position: relative;
  top: unset !important;
  align-self: flex-end;
  width: 100%;
  margin-inline-end: -18px;
  margin-bottom: -34px;

  ${() => {
    const { isMobile, isTablet, isWideDesktop } = useDeviceDetect();
    const isRtl = useLangStore((state) => state.isRtl());
    return `
      width: ${isMobile || isTablet || isWideDesktop ? '100%' : '50%'};
      flex:  ${isMobile || isTablet || isWideDesktop ? '1' : '0'};
      transform: ${isRtl ? 'rotateY(180deg)' : 'none'};
    `;
  }}
`;

export const StyledLeftCol = styled(StyledCol)`
  flex: 1;
  min-width: 253px;
`;
