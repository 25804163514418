import { useAxios } from '@/services/apiClient';

export const usePolicies = () => {
  const [{ data: policyData }, getPolicy] = useAxios(
    { method: 'get', url: '/terms/last' },
    { manual: true }
  );

  return { getPolicy, policyData };
};
