import { StyledCol } from '@/shared/styles';
import { FooterLink } from '../FooterLink';
import { FooterLinksSectionProps, LinksSectionProps } from '../FooterSections.consts';
import { v4 as uuidv4 } from 'uuid';

export const LinksSection: React.FC<LinksSectionProps> = ({ links }: LinksSectionProps) => {
  return (
    <StyledCol $gap={16}>
      {links.map((link: FooterLinksSectionProps) => (
        <FooterLink key={uuidv4()} name={link.name} to={link.to} />
      ))}
    </StyledCol>
  );
};
