import { StyledCol, StyledPurpleTitle } from '@/shared/styles';
import { useTranslation } from 'react-i18next';
import { useLangStore } from '@/stores/LangStore/LangStore';
import { StyledLandingPurpleSubtitle, StyledLandingText } from '../../../LandingPages.styles';

export const ImportanceDescription: React.FC = () => {
  const { t } = useTranslation();
  const isRtl = useLangStore((state) => state.isRtl());
  return [...Array(3)].map((_, i) => (
    <StyledCol $gap={16} key={`importance${i}`}>
      <StyledLandingPurpleSubtitle
        $visualSizeLevel={2}
        level={3}
        $align={isRtl ? 'right' : 'left'}
        $width={isRtl ? 'fit-content' : '100%'}
      >
        {t(`landing_page.importance.block${i + 1}.title`)}:
      </StyledLandingPurpleSubtitle>
      <StyledLandingText fontSize={'18px'}>
        {t(`landing_page.importance.block${i + 1}.content`)}
      </StyledLandingText>
    </StyledCol>
  ));
};
