import styled from 'styled-components';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledRow } from '@/shared/styles';

export const StyledTrustedCompaniesGrid = styled(StyledRow)`
  ${() => {
    const { isMobile, isWideDesktop } = useDeviceDetect();

    return `
      flex-wrap: wrap;
      justify-content: center;
      gap: 0 60px;

      img {
        max-width: ${isMobile ? '70' : '100'}px;
        object-fit: contain;
      }

      ${
        isWideDesktop &&
        `
          justify-content: space-between;
          gap: 0 80px;
        `
      }`;
  }};
`;

export const StyledTrustedCompaniesLogo = styled.div`
  flex: 0 0 auto;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 104px;
`;
