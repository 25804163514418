import React from 'react';
import {
  StyledAccordionButton,
  StyledAccordionContentContainer,
  StyledAccordionContentText,
  StyledAccordionItem,
  StyledAccordionItemSeparator,
} from './Accordion.styles';
import { AccordionItemProps } from './Accordion.consts';
import { StyledRowOffset } from '@/shared/styles';
import { processTextContent } from '@/shared/pages/Policies/PolicySection/PolicySection.utils';

export const AccordionItem: React.FC<AccordionItemProps> = ({ showDescription, item, onClick }) => {
  return (
    <StyledAccordionItem>
      <StyledRowOffset $gap={12}>
        {item.additionalItem && item.additionalItem}
        <StyledAccordionButton
          data-u1-accordion="header"
          showDescription={showDescription}
          onClick={onClick}
        >
          {item.title}
        </StyledAccordionButton>
      </StyledRowOffset>
      <StyledAccordionContentContainer data-u1-accordion="content">
        <StyledAccordionContentText showDescription={showDescription}>
          {processTextContent(item.content)}
        </StyledAccordionContentText>
      </StyledAccordionContentContainer>
      <StyledAccordionItemSeparator />
    </StyledAccordionItem>
  );
};
