import { SignInProps } from '@/shared/props';
import React from 'react';
import { isBrowser } from 'react-device-detect';
import { StyledLayout } from './Signin.styles';
import { SignInLeftSection } from './SignInLeftSection';
import { SignInRightSection } from './SignInRightSection';
import { MetaTags } from '@/shared/seo';

export const SignIn: React.FC<SignInProps> = ({ isFromInvite }) => {
  return (
    <>
      <MetaTags
        title="metatag.sign_in.title"
        description="metatag.sign_in.description"
        keywords="metatag.sign_in.keywords"
      />
      <StyledLayout>
        <SignInLeftSection isFromInvite={isFromInvite} />
        {isBrowser && <SignInRightSection />}
      </StyledLayout>
    </>
  );
};
