import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { Image } from '@/shared/components/Image/Image';
import { StyledCol, StyledRow } from '@/shared/styles';
import { AdditionalInfo } from './AdditionalInfo';
import {
  StyledAdaptiveInstitutions,
  StyledInstiturionsCol,
  StyledInstitutionBox,
} from './Institutions.styles';
import { getInstitutionImages } from './Institutions.consts';
import {
  StyledLandingContainer,
  StyledLandingPageSection,
  StyledLandingPurpleTitle,
  StyledLandingText,
} from '../../../LandingPages.styles';

export const Institutions: React.FC = () => {
  const { isMobile, isTablet } = useDeviceDetect();
  const { t } = useTranslation();

  return (
    <StyledLandingPageSection>
      <StyledLandingContainer $size={'md'} $gap={isMobile ? 32 : 16}>
        <StyledInstiturionsCol>
          <StyledLandingPurpleTitle $visualSizeLevel={isMobile ? 4 : 2} level={2}>
            {t('landing_page.institutions.title')}:
          </StyledLandingPurpleTitle>
        </StyledInstiturionsCol>
        <StyledCol $gap={isMobile ? 32 : 40}>
          <StyledRow $gap={isMobile ? 32 : 40} $direction={isMobile || isTablet ? 'column' : 'row'}>
            <StyledCol $gap={isMobile ? 32 : 16}>
              <StyledLandingText fontSize={isMobile ? '16px' : '18px'}>
                {t('landing_page.institutions.content')}
              </StyledLandingText>
              {!isMobile && (
                <StyledLandingText fontSize={isMobile ? '16px' : '18px'}>
                  {t(`landing_page.institutions.block1`)}
                </StyledLandingText>
              )}
            </StyledCol>

            {!isMobile && (
              <StyledCol $gap={24}>
                <StyledLandingText fontSize={isMobile ? '16px' : '18px'}>
                  {t(`landing_page.institutions.block2`)}
                </StyledLandingText>
                <StyledLandingText fontSize={isMobile ? '16px' : '18px'}>
                  {t(`landing_page.institutions.block3`)}
                </StyledLandingText>
              </StyledCol>
            )}
          </StyledRow>
          <StyledAdaptiveInstitutions>
            {getInstitutionImages().map((ins) => (
              <StyledInstitutionBox key={ins.alt}>
                <Image $src={ins.img} $alt={ins.alt} />
              </StyledInstitutionBox>
            ))}
          </StyledAdaptiveInstitutions>
          {isMobile && <AdditionalInfo />}
        </StyledCol>
      </StyledLandingContainer>
    </StyledLandingPageSection>
  );
};
