import React, { ReactElement } from 'react';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledAdjustableCol, StyledCol } from '@/shared/styles';
import { StyledBanner, StyledText } from './TextAndBanner.styles';
import { TextAndBannerProps } from './TextAndBanner.consts';
import { StyledLandingContainer, StyledLandingText } from '../../LandingPages.styles';

export const TextAndBanner: React.FC<TextAndBannerProps> = ({
  title,
  leftText,
  rightText,
  bannerImg,
  imgAlt,
}: TextAndBannerProps) => {
  const { isDesktop, isWideDesktop } = useDeviceDetect();

  return (
    <StyledLandingContainer $size={'md'}>
      <StyledCol $gap={isDesktop || isWideDesktop ? 16 : 32}>
        {React.cloneElement(title as ReactElement, {
          level: 2,
        })}
        <StyledAdjustableCol
          $gap={isDesktop || isWideDesktop ? 40 : 32}
          $align="start"
          $wrap={'wrap'}
          $justify="center"
        >
          <StyledText>
            <StyledLandingText fontSize={'18px'}>{leftText}</StyledLandingText>
            <StyledLandingText fontSize={'18px'}>{rightText}</StyledLandingText>
          </StyledText>
          <StyledBanner src={bannerImg} alt={imgAlt ?? ''} />
        </StyledAdjustableCol>
      </StyledCol>
    </StyledLandingContainer>
  );
};
