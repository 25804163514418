export enum Resource {
  ENTERPRISE_LANDING_PAGE = 'ENTERPRISE_LANDING_PAGE',
  HOME_LANDING_PAGE = 'HOME_LANDING_PAGE',
  EAA_LANDING_PAGE = 'EAA_LANDING_PAGE',
  PRICING_BLOCK = 'PRICING_BLOCK',
  UNKNOWN = 'UNKNOWN',
}

export const ResourceKeyPath = {
  ENTERPRISE_LANDING_PAGE: 'lp/enterprise',
  EAA_LANDING_PAGE: 'lp/eaa',
  HOME_LANDING_PAGE: 'lp/home',
  PRICING_BLOCK: 'pricing',
};
