import { TextProps } from '@/shared/props';
import { MainTitleProps } from '@/Views/BeforeLogin/BeforeLogin.consts';
import { StyledMainTitle, StyledSubTitle } from '@/Views/BeforeLogin/BeforeLogin.styles';
import styled from 'styled-components';

export const StyledGetStartedPurpleTitle = styled(StyledMainTitle)<MainTitleProps>`
  color: ${(props) => props.theme.lightWhiteDarkPurpleText} !important;
`;

export const StyledGetStartedPurpleSubTitle = styled(StyledSubTitle)<MainTitleProps>`
  color: ${(props) => props.theme.lightWhiteDarkPurpleText} !important;
`;

export const StyledGetStartedPurpleText = styled.span<TextProps>`
  color: ${(props) => props.theme.lightWhiteDarkPurpleText} !important;
  word-break: keep-all;
`;

export const StyledGetStartedWhiteText = styled.span`
  color: ${(props) => props.theme.sameColorText} !important;
`;
