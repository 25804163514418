import { StyledCol } from '@/shared/styles';
import { InfoCardProps } from '../Cards.consts';
import { StyledElongatedCard } from '../Cards.styles';
import { StyledLandingText } from '../../../LandingPages.styles';
import { Image } from '@/shared/components/Image/Image';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from '@/shared/themes/darkTheme';
import { StyledSubTitle } from '@/Views/BeforeLogin/BeforeLogin.styles';

export const ElongatedInfoCard: React.FC<InfoCardProps> = ({
  $title,
  $content,
  $img,
}: InfoCardProps) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <StyledElongatedCard>
        <StyledCol $gap={16}>
          <StyledSubTitle $visualSizeLevel={2} level={3}>
            {$title}
          </StyledSubTitle>
          <StyledLandingText fontSize="18px">{$content}</StyledLandingText>
        </StyledCol>
        <Image $src={$img as string} />
      </StyledElongatedCard>
    </ThemeProvider>
  );
};
