import { U1Icon } from '@/assets/images/icons';
import { useU1Navigation } from '@/hooks/useU1Navigation';
import { useLangStore } from '@/stores/LangStore/LangStore';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

const DEFAULT = {
  TITLE: 'metatag.default.title',
  DESCRIPTION: 'metatag.default.description',
  KEYWORDS: 'metatag.default.keywords',
  IMAGE_URL: U1Icon,
};

type MetaTagProps = {
  title?: string;
  description?: string;
  keywords?: string;
  imageUrl?: string;
  route?: string;
  locale?: string;
  robotsTags?: string[];
  robots?: {
    index?: boolean;
    follow?: boolean;
  };
};

const BASE_ROBOT_TAGS = ['follow', 'index'];

const isClientSide = () => typeof window !== 'undefined';

export const useWindowLocation = () => {
  if (!isClientSide()) {
    throw new Error("You're not in a client-side browser environment!");
  }

  return window.location;
};

export const MetaTags = ({
  title = DEFAULT.TITLE,
  description = DEFAULT.DESCRIPTION,
  keywords = DEFAULT.KEYWORDS,
  imageUrl = DEFAULT.IMAGE_URL,
  locale,
  robotsTags = [],
}: MetaTagProps) => {
  const location = useLocation();
  const { t } = useTranslation();
  const { origin } = useWindowLocation();
  const { removeLanguageFromPath } = useU1Navigation();
  const langStore = useLangStore();

  const fullUrl = `${origin}${location.pathname}`;

  const links = useMemo(() => {
    const pathname = removeLanguageFromPath(location.pathname);

    const generatedLinks = langStore.availableLangs.map(({ code }) => (
      <link key={code} rel="alternate" hrefLang={code} href={`${origin}/${code}/${pathname}`} />
    ));

    generatedLinks.push(
      <link key="x-default" rel="alternate" hrefLang="x-default" href={`${origin}/${pathname}`} />
    );

    return generatedLinks;
  }, [langStore.availableLangs, location.pathname, origin]);

  title = t(title);
  description = t(description);
  keywords = t(keywords);

  return (
    <Helmet>
      <html lang={langStore.getSelectedLang()} dir={langStore.getLangDir() ?? 'ltr'} />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="apple-mobile-web-app-title" content={title} />

      {/* twitter */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={fullUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={imageUrl} />

      {/* og */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={fullUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={locale ?? 'en-US'} />

      {/* robots */}
      <meta name="robots" content={(robotsTags.length ? robotsTags : BASE_ROBOT_TAGS).join(', ')} />

      {/* languages */}
      {links}
      <link rel="canonical" href={fullUrl} />
    </Helmet>
  );
};
