import React from 'react';
import type { MenuProps } from 'antd';
import { SideMenu } from './SideMenu';
import { PAGES, SIDEMENU_NAVIGATION } from '@/shared/consts';
import { useU1Navigation } from '@/hooks/useU1Navigation';

export const VendorSideMenu: React.FC = () => {
  const { navigate } = useU1Navigation();

  const onClick: MenuProps['onClick'] = (e) => {
    switch (e.key) {
      case SIDEMENU_NAVIGATION.DEALS:
        navigate(PAGES.AFTER_LOGIN.DEALS);
        break;
      case SIDEMENU_NAVIGATION.STATS:
        navigate(PAGES.AFTER_LOGIN.STATS);
        break;
      case SIDEMENU_NAVIGATION.SETTINGS:
        navigate(PAGES.AFTER_LOGIN.SETTINGS);
        break;
    }
  };
  return <SideMenu onClick={(e) => onClick(e)} defaultPage={SIDEMENU_NAVIGATION.DEALS} />;
};
