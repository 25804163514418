import styled from 'styled-components';
import { StyledCol } from '@/shared/styles';
import { ButtonBannerContainerProps } from './ButtonBanner.consts';
import { StyledLandingText } from '../../LandingPages.styles';

export const StyledButtonBanner = styled(StyledCol)<ButtonBannerContainerProps>`
  border: 2px solid ${(props) => props.theme.landingPageBorder};
  border-radius: 24px;
  align-items: center;
  padding: 40px 0;

  ${(props) => `
      background-color: ${props.theme.sameColorBG};
      background-size: 200% 400%;
      background-position: center;
      background-image: ${props.theme.accentCornersGradient};
    `};
`;

export const StyledGetStartedText = styled(StyledLandingText)`
  color: ${(props) => props.theme.sameColorText};
  z-index: 1;
`;
