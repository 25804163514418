import React from 'react';
import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import enterpriseSuitabilityMobile from '@/assets/images/landingPage/enterprise/enterpriseSuitabilityMobile.png';
import enterpriseSuitabilityTablet from '@/assets/images/landingPage/enterprise/enterpriseSuitabilityTablet.png';
import enterpriseSuitabilityDesktop from '@/assets/images/landingPage/enterprise/enterpriseSuitabilityDesktop.png';
import { TextAndBanner } from '../../../Common/TextAndBanner/TextAndBanner';
import { StyledLandingPageSection, StyledLandingPurpleTitle } from '../../../LandingPages.styles';

export const EnterpriseSuitability: React.FC = () => {
  const { isMobile, isTablet } = useDeviceDetect();
  const { t } = useTranslation();

  return (
    <StyledLandingPageSection>
      <TextAndBanner
        title={
          <StyledLandingPurpleTitle $visualSizeLevel={isMobile ? 4 : 2} level={2}>
            {t('landing_page.enterprise.suitability.title')}
          </StyledLandingPurpleTitle>
        }
        leftText={t('landing_page.enterprise.suitability.leftText')}
        rightText={t('landing_page.enterprise.suitability.rightText')}
        bannerImg={
          isMobile
            ? enterpriseSuitabilityMobile
            : isTablet
            ? enterpriseSuitabilityTablet
            : enterpriseSuitabilityDesktop
        }
        imgAlt={t('landing_page.enterprise.suitability.imgAlt')}
      />
    </StyledLandingPageSection>
  );
};
