import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/shared/components/Icon';
import { StyledCol, StyledRow } from '@/shared/styles';
import { socialMedia } from './Footer.consts';
import {
  StyledFooterContent,
  StyledFooterLinkNoStyle,
  StyledFooterUserDetails,
} from './Footer.styles';
import { U1LandingPageLogo } from '@/shared/components/U1LandingPageLogo';

export const User1stDetails: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledFooterUserDetails $maxWidth="190px">
      <U1LandingPageLogo $maxWidth={190} />
      <StyledCol $gap={16}>
        <StyledFooterLinkNoStyle to="mailto:support@user1st.com">
          support@user1st.com
        </StyledFooterLinkNoStyle>
        <StyledFooterContent>{t('landing_page.footer.address')}</StyledFooterContent>
      </StyledCol>
      <StyledRow $gap={20}>
        {socialMedia.map((social) => (
          <a
            aria-label={social.name}
            href={social.href}
            key={social.name}
            target="_blank"
            rel="noreferrer"
          >
            <Icon $src={social.icon} $alt="" $loading="lazy" />
          </a>
        ))}
      </StyledRow>
    </StyledFooterUserDetails>
  );
};
