import styled from 'styled-components';
import { StyledAdjustableRow } from '@/shared/styles';
import useDeviceDetect from '@/hooks/useDeviceDetect';

export const StyledOurClientsRow = styled(StyledAdjustableRow)`
  align-items: center;

  ${() => {
    const { isMobile, isTablet } = useDeviceDetect();

    return `
      flex-direction: ${isMobile || isTablet ? 'column' : 'row'} !important;
      img {
        max-width: ${isMobile || isTablet ? '768px' : '610px'};
      }
    `;
  }}
`;
