import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { darkTheme } from '@/shared/themes/darkTheme';
import { ButtonBanner } from '../../../Common/ButtonBanner/ButtonBanner';
import { DemoModal } from '../../../Common/DemoModal/DemoModal';
import { StyledLandingContainer, StyledLandingPageSection } from '../../../LandingPages.styles';
import { StyledMainTitle } from '@/Views/BeforeLogin/BeforeLogin.styles';

export const ScheduleDemo: React.FC = () => {
  const { isDesktop, isMobile } = useDeviceDetect();
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={darkTheme}>
      <StyledLandingPageSection>
        <StyledLandingContainer $size={'md'}>
          <ButtonBanner
            $title1={
              <StyledMainTitle $visualSizeLevel={3} level={2} $align="center">
                {t('landing_page.eaa.schedule_demo.title')}
              </StyledMainTitle>
            }
            $content={t('landing_page.eaa.schedule_demo.content')}
            $button={
              <DemoModal
                $btnText={t('landing_page.eaa.schedule_demo.btn')}
                $refName="contact-us-demo"
                $btnWidth={isDesktop ? 'fit-content' : '100%'}
                $btnMinWidth={isMobile ? 'calc(100% + 16px)' : 'auto'}
                $btnMargin={isMobile ? '0 -8px' : '0'}
              />
            }
          />
        </StyledLandingContainer>
      </StyledLandingPageSection>
    </ThemeProvider>
  );
};
