import { COLORS } from '../colors';
import { ThemeSchema } from './themeSchema';

export const darkTheme: ThemeSchema = {
  textColor: `${COLORS.WHITE}`,
  textGradient: ``,
  orangeTextGradient: `${COLORS.YELLOW_ORANGE_GRADIENT}`,
  purpleTextGradient: `${COLORS.PURPLE_WHITE_GRADIENT}`,
  secondaryTextColor: `${COLORS.LIGHT_GRAY}`,
  backgroundColor: `${COLORS.DARK_GRAY}`,
  cardsColor: `${COLORS.MEDIUM_GRAY}`,
  marginsColor: `${COLORS.PURPLE_GRAY}`,
  secondMarginsColor: `${COLORS.MEDIUM_GRAY}`,
  marginsBorder: `${COLORS.GRAY_PURPLE}`,
  borderColor: `${COLORS.PURPLE}`,
  detailsBorder: `${COLORS.LIGHT_PURPLE_GRAY}`,
  menuTextColor: `${COLORS.DUSTY_PINK}`,
  inputColor: `${COLORS.PURPLE_GRAY}`,
  inputBorder: `${COLORS.LIGHT_PURPLE_GRAY}`,
  errorColor: `${COLORS.PALE_RED}`,
  errorBG: `${COLORS.DUSTY_RED}`,
  buttonsBG: `${COLORS.PURPLE_GRADIENT}`,
  buttonsBGHorizontal: `${COLORS.PURPLE_GRADIENT_HORIZONTAL}`,
  twoColorButtonBG: `${COLORS.TWO_COLOR_PURPLE_GRADIENT}`,
  selectedButtonBG: `${COLORS.LIGHT_PURPLE_GRAY}`,
  signInButtonsBgColor: `${COLORS.WHITE}`,
  signInButtonsBasicFontColor: `${COLORS.BLACK}`,
  disabledColor: `${COLORS.LIGHT_PURPLE_GRAY}`,
  disabledText: `${COLORS.DEEP_PURPLE}`,
  buttonHoverBG: `${COLORS.WHITE_TO_PINK_GRADIENT}`,
  tableHeadBorderColor: `${COLORS.LIGHT_PURPLE_GRAY}`,
  shadowColor: `${COLORS.LIGHT_GRAY_OPACITY}`,
  disabledItemColor: `${COLORS.DEEP_PURPLE}`,
  menuItemHoverColor: `${COLORS.EXTRA_DARK_GRAY}`,
  cardsBgColor: `${COLORS.DARK_PURPLE_GRAY}`,
  gradientBorder: `${COLORS.YELLOW_ORANGE_GRADIENT}`,
  accentCornersGradient: `${COLORS.PURPLE_GRADIENT_RADIAL}`,
  linkGradient: `${COLORS.PINK_TO_WHITE_GRADIENT}`,
  successStatus: `${COLORS.GREEN}`,
  shimmerColor: `${COLORS.DARK_PURPLE_GRADIENT}`,
  toggleDisabled: `${COLORS.GRAY_PURPLE}`,
  a11yBorder: `${COLORS.WHITE}`,
  secondBorder: `${COLORS.GRAY_PURPLE}`,
  gradientBg: `${COLORS.PURPLE_GRADIENT}`,
  successfulProgress: `${COLORS.GREEN_GRADIENT}`,
  darkInputColor: `${COLORS.DARK_PURPLE}`,
  graphStrokeStartColor: `${COLORS.MEDIUM_YELLOW}`,
  graphStrokeEndColor: `${COLORS.REDDISH_PINK}`,
  graphMarkerColor: `${COLORS.REDDISH_PINK}`,
  graphCrosshair: `${COLORS.PURPLE_OPACITY}`,
  selectedDay: `${COLORS.PURPLE_GRAY}`,
  selectedRange: `${COLORS.SIMPLE_PURPLE_GRAD}`,
  focusedText: `${COLORS.BLACK}`,
  bgContrast: `${COLORS.BLACK}`,
  bgNoContrast: `${COLORS.WHITE}`,
  textContrast: `${COLORS.MEDIUM_YELLOW}`,
  textNoContrast: `${COLORS.LIGHT_GRAY}`,
  darkShadow: `${COLORS.BLACK_OPACITY}`,
  landingPageBorder: `${COLORS.SHINY_PURPLE}`,
  brandColor: `${COLORS.U1_BRAND_PURPLE}`,
  gradientButtonInnerText: `${COLORS.WHITE}`,
  copyButtonBG: `${COLORS.PURPLE_GRAY}`,
  placeholderColor: `${COLORS.DEEP_PURPLE}`,
  accordionBorder: `${COLORS.PURPLE_GRAY}`,
  sameColorBG: `${COLORS.PURPLE_GRAY}`,
  sameColorText: `${COLORS.WHITE}`,
  sameColorBox: `${COLORS.DARK_GRAY}`,
  sameColorGradient: `${COLORS.PURPLE_WHITE_GRADIENT}`,
  sameColorBorder: `${COLORS.GRAY_PURPLE}`,
  purpleText: `${COLORS.EXTRA_LIGHT_PURPLE}`,
  lightPurpleDarkWhiteText: `${COLORS.WHITE}`,
  lightWhiteDarkPurpleText: `${COLORS.EXTRA_LIGHT_PURPLE}`,
  lpCardBorder: `${COLORS.LIGHT_GRAY}`,
  overlayTextBG: `${COLORS.MEDIUM_GRAY}`,
  overlayTextBorder: `${COLORS.LAVENDER_BLUE}`,
  sameColorPurple: `${COLORS.EXTRA_LIGHT_PURPLE}`,
};
