import styled from 'styled-components';
import { StyledAdjustableRow, StyledCard, StyledCol, StyledRow } from '@/shared/styles';
import { StyledLandingText } from '../../../LandingPages.styles';
import useDeviceDetect from '@/hooks/useDeviceDetect';

export const StyledSolutionsCard = styled(StyledCard)`
  height: 90px;
  width: 100%;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  background-color: ${(props) => props.theme.overlayTextBG};

  border-radius: 16px;
  border: 2px solid ${(props) => props.theme.overlayTextBorder};
`;

export const StyledSolutionsCardText = styled(StyledLandingText)`
  color: ${(props) => props.theme.sameColorText} !important;
`;

export const StyledModernCompatibilityRow = styled(StyledAdjustableRow)`
  gap: 40px;
  justify-items: center;

  ${() => {
    const { isMobile, isTablet } = useDeviceDetect();

    return `
      flex-direction: ${isMobile || isTablet ? 'column' : 'row'} !important;
    `;
  }}

  img {
    border: 2px solid ${(props) => props.theme.overlayTextBorder};
    border-radius: 24px;
  }
`;

export const StyledModernCompatibilityCol = styled(StyledCol)`
  gap: 40px;
`;

export const StyledCompatibilityRow = styled(StyledRow)`
  display: grid;
  justify-items: center;

  ${() => {
    const { isMobile } = useDeviceDetect();

    return `
      grid-template-columns: repeat(${isMobile ? '2' : '4'}, 1fr);
    `;
  }}
`;
