import { pageIcon, selectedPageIcon } from '@/assets/images/icons';
import { IconButton } from '@/shared/components/Button/IconButton';
import { StyledRow } from '@/shared/styles';
import { PageSliderProps } from '../../../Client/Projects/projects.consts';
import { scrollToRef } from '@/shared/utils';

export const PageSlider: React.FC<PageSliderProps> = ({
  docsRef: ref,
  page,
  setPage,
  numOfPages,
}: PageSliderProps) => {
  const goToPage = (page: number) => {
    setPage(page);
    scrollToRef(ref);
  };

  return (
    <StyledRow>
      {Array.from({ length: numOfPages }, (_, index) => {
        const pageNum = index + 1;
        return (
          <IconButton
            key={index}
            $icon={page === pageNum ? selectedPageIcon : pageIcon}
            onClick={() => goToPage(pageNum)}
          />
        );
      })}
    </StyledRow>
  );
};
