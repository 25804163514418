export enum ScreenBreakpoint {
  MOBILE = 414,
  NARROW_TABLET = 640,
  TABLET = 834,
  DESKTOP = 1024,
  WIDE_DESKTOP = 1440,
}

export type Primitive = string | boolean | number;

export type WindowDimensions = {
  width: number;
  height: number;
};
