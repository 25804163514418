import { LandingPageTemplate } from '../LandingPageTemplate';
import { BenefitsOfComplience } from './Areas/Benefits/Benefits';
import { ComplianceAndExperience } from './Areas/ComplianceAndExperience/ComplianceAndExperience';
import { Intro } from './Areas/Intro/Intro';
import { Introduction } from './Areas/IntroductionToEAA/Introduction';
import { OurFeaturesEAA } from './Areas/OurFeatures/OurFeaturesEAA';
import { ScheduleDemo } from './Areas/ScheduleDemo/ScheduleDemo';
import { TrustedEnterprises } from './Areas/Trusted/TrustedEnterprises';
import { U1Toolset } from './Areas/U1Toolset/U1Toolset';

export const EAALandingPage: React.FC = () => {
  return (
    <LandingPageTemplate>
      <Intro />
      <Introduction />
      <U1Toolset />
      <ComplianceAndExperience />
      <TrustedEnterprises />
      <OurFeaturesEAA />
      <BenefitsOfComplience />
      <ScheduleDemo />
    </LandingPageTemplate>
  );
};
