import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledCol } from '@/shared/styles';
import { TrustedSliderProps } from './Trusted.consts';
import { LogoSlider } from './LogoSlider';
import { LogosGrid } from './LogosGrid';
import {
  StyledLandingContainer,
  StyledLandingPageSection,
  StyledLandingPurpleTitle,
  StyledLandingText,
} from '../../LandingPages.styles';

export const Trusted: React.FC<TrustedSliderProps> = (props: TrustedSliderProps) => {
  const { isMobile } = useDeviceDetect();
  const { $title, $content, $iconsColor, $isStretched, $isSlider = true, ...restOfProps } = props;

  return (
    <StyledLandingPageSection {...restOfProps}>
      <StyledLandingContainer $size={'md'}>
        <StyledCol $gap={isMobile ? 16 : 32}>
          <StyledLandingPurpleTitle level={2} $visualSizeLevel={isMobile ? 4 : 2} $align="center">
            {$title}
          </StyledLandingPurpleTitle>
          {$content && (
            <StyledLandingText fontSize={'18px'} align="center">
              {$content}
            </StyledLandingText>
          )}
          {$isSlider ? (
            <LogoSlider $iconsColor={$iconsColor} $isStretched={$isStretched} />
          ) : (
            <LogosGrid />
          )}
        </StyledCol>
      </StyledLandingContainer>
    </StyledLandingPageSection>
  );
};
