import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { useU1Navigation } from '@/hooks/useU1Navigation';
import { GradientRoundedButton } from '@/shared/components/Button/GradientRoundedButton';
import { ThemeSwitcher } from '@/shared/components/ToggleButton/ThemeSwitcher/ThemeSwitcher';
import { StyledRow } from '@/shared/styles';
import { PAGES } from '@/shared/consts';
import { LangSwitch } from '@/Views/BeforeLogin/Header/Lang/LangSwitch';
import { StyledLPHeader, StyledLPHeaderContent } from './Header.styles';
import { StyledLandingContainer } from '../../LandingPages.styles';
import { U1LandingPageLogo } from '@/shared/components/U1LandingPageLogo';

export const LandingPageHeader: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const { navigate } = useU1Navigation();

  return (
    <StyledLPHeader>
      <StyledLandingContainer $size={'lg'}>
        <StyledLPHeaderContent>
          <U1LandingPageLogo $maxWidth={isMobile ? 111 : 190} $height={isMobile ? 38 : 66} />
          <StyledRow $gap={isMobile ? 16 : 32} $align="center">
            <ThemeSwitcher />
            {!isMobile && <LangSwitch />}
            <GradientRoundedButton
              text={t('header.sign_in')}
              onClick={() => navigate(PAGES.BEFORE_LOGIN.SIGN_IN)}
            />
          </StyledRow>
        </StyledLPHeaderContent>
      </StyledLandingContainer>
    </StyledLPHeader>
  );
};
