import { Resource, ResourceKeyPath } from './ContactUs.consts';

export const getResource = () => {
  const path = location.pathname;

  for (const [key, value] of Object.entries(ResourceKeyPath)) {
    if (path.includes(value)) {
      return Resource[key as keyof typeof Resource];
    }
  }

  return Resource.UNKNOWN;
};
