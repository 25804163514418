import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledCol, StyledPurpleText } from '@/shared/styles';
import {
  StyledHybridTitle,
  StyledLandingPurpleSubtitle,
  StyledLandingText,
} from '../../../LandingPages.styles';
import { DemoModal } from '../../../Common/DemoModal/DemoModal';

export const IntroContent: React.FC = () => {
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const { t } = useTranslation();

  return (
    <StyledCol $gap={32} $maxWidth={isMobile || isTablet ? '100%' : '760px'}>
      <StyledCol $gap={8}>
        <StyledLandingPurpleSubtitle
          level={1}
          $visualSizeLevel={isMobile || isDesktop ? 2 : 1}
          $weight={600}
          $align="start"
        >
          {t('landing_page.intro.title1.welcome')}:{isMobile ? <br /> : <></>}{' '}
          {t('landing_page.intro.title1.your_partner')}
        </StyledLandingPurpleSubtitle>
        <StyledHybridTitle
          level={2}
          $break={false}
          $visualSizeLevel={isMobile || isDesktop ? 2 : 1}
        >
          {t('landing_page.intro.title2.empowering')} {isMobile ? ' ' : <br />}
          {t('with')}{' '}
          <StyledPurpleText fontSize={'inherit'}>
            {t('landing_page.intro.title2.in_code')}
          </StyledPurpleText>
        </StyledHybridTitle>
        <StyledLandingText>{t('landing_page.intro.content')}</StyledLandingText>
      </StyledCol>
      <DemoModal
        $btnText={t('landing_page.request_demo')}
        $btnWidth={isMobile || isTablet ? '100%' : 'fit-content'}
      />
    </StyledCol>
  );
};
