import styled from 'styled-components';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledRow } from '@/shared/styles';
import { ImageContainerProps } from './TextInsideImage.consts';

export const StyledImageContainer = styled(StyledRow)<ImageContainerProps>`
  border: 2px solid ${(props) => props.theme.overlayTextBorder};
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 16px;
  width: ${(props) => props.$width ?? `100%`};
  overflow: hidden;

  ${() => {
    const { isMobile, isDesktop } = useDeviceDetect();

    return `
        height: ${isMobile ? `471px` : '436px'};
        ${isDesktop ? 'min-width: calc(50% - 20px)' : 'min-width: auto'};
    `;
  }}
`;

export const StyledOverlayTextContainer = styled.div`
  color: ${(props) => props.theme.sameColorText} !important;
  background: ${(props) => props.theme.overlayTextBG};
  border: 2px solid ${(props) => props.theme.overlayTextBorder};
  border-radius: 16px;
  padding: 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  z-index: 1;

  ${() => {
    const { isMobile } = useDeviceDetect();

    return `
        margin: ${isMobile ? `0 -2px -2px` : 'auto -2px -2px'};
    `;
  }}
`;
