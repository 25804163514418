import { Primitive } from './WindowDimensions.consts';
import { BreakpointHandler } from './BreakpointHandler';
import { useState, useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { getWindowDimensions } from './WindowDimensions.utils';

export function useWindowBreakpoints<T extends Record<string, Primitive>>(
  callback: (handler: BreakpointHandler) => T,
  debounceTime = 120
): T {
  const [state, setState] = useState<T>(() =>
    callback(new BreakpointHandler(getWindowDimensions()))
  );

  const handleStateUpdate = useDebouncedCallback(() => {
    const newState = callback(new BreakpointHandler(getWindowDimensions()));

    if (!newState) {
      return;
    }

    setState((prevState) => {
      const isTheSame = Object.entries(newState).every(
        ([key, value]) => key in prevState && prevState[key] === value
      );

      if (isTheSame) {
        return prevState;
      }

      return newState;
    });
  }, debounceTime);

  useEffect(() => {
    window.addEventListener('resize', handleStateUpdate);
    return () => window.removeEventListener('resize', handleStateUpdate);
  }, [handleStateUpdate]);

  return state;
}
