import { TitleProps } from '@/shared/props';

export const noHeaderPages = ['sign-in', 'admin', 'vendor', 'password', 'security-alert', 'v1'];
export const mainPages = ['', 'home'];
export const pagesWithNoFooter = ['v1', 'sign-in', 'vendor', 'admin'];

export type BeforeLoginMainProps = {
  $bg?: string;
};

export type MainTitleProps = TitleProps & {
  $visualSizeLevel?: 1 | 2 | 3 | 4 | 5;
};

export type SubTitleProps = TitleProps & {
  $visualSizeLevel?: 1 | 2 | 3;
};
