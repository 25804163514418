import { FrameProps } from '@/shared/props';
import { StyledHeader } from '@/shared/styles';
import styled from 'styled-components';

export const StyledLPHeader = styled(StyledHeader)<FrameProps>`
  background-color: ${(props) => props.theme.sameColorBG} !important;
  border-bottom: none !important;
  padding-left: 0;
  padding-right: 0;

  .anticon,
  .ant-select-item,
  .ant-select-selection-item {
    color: ${(props) => props.theme.sameColorText} !important;
  }
`;

export const StyledLPHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;
