import { PropsWithChildren } from 'react';

export type LandingPageProps = PropsWithChildren & {
  $allowGaps?: boolean;
};

export type LandingPageSectionProps = {
  $bgColor?: string;
  $bgImg?: string;
  $paddingTop?: number;
  $paddingBottom?: number;
  $paddingRight?: number;
  $paddingLeft?: number;
  $marginTop?: number;
  $marginBottom?: number;
};

export type LandingPageContainerProps = {
  $size?: 'lg' | 'md' | 'sm' | 'full';
  $gap?: number;
};

export type TitleColorProps = LandingPageSectionProps & {
  $titleAlign?: 'left' | 'center' | 'right';
};

export type LPTitleProps = TitleColorProps & {
  $align?: string;
  $size?: number;
  $weight?: number;
};

export const eaaBgGradients: Record<string, string> = {
  intro: 'linear-gradient(180deg, #212121 32.51%, #352155 72.76%, #36276A 95%);',
  introduction: 'linear-gradient(180deg, #36276A 5%, #352155 41%, #212121 100%);',
  trusted: 'linear-gradient(180deg, #212121 12.79%, #352155 64.24%, #36276A 95%);',
  features: 'linear-gradient(180deg, #36276A 5%, #352155 41%, #212121 100%);',
};
