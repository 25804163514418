import React from 'react';
import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import documentationTableExample from '@/assets/images/landingPage/enterprise/documentationTableExample.svg';
import { Image } from '@/shared/components/Image/Image';
import { StyledLandingContainer, StyledLandingPageSection } from '../../../LandingPages.styles';
import { CompatibilityDescription } from './CompatibilityDescription';
import {
  StyledModernCompatibilityCol,
  StyledModernCompatibilityRow,
} from './ModernCompatibility.styles';

export const ModernCompatibility: React.FC = () => {
  const { isMobile, isTablet, isDesktop } = useDeviceDetect();
  const { t } = useTranslation();
  const alt = t('landing_page.enterprise.compatibility.imgAlt');

  return (
    <StyledLandingPageSection>
      <StyledLandingContainer $size={'md'}>
        <StyledModernCompatibilityRow
          $wrap={isMobile || isTablet ? 'wrap' : 'nowrap'}
          $align={isMobile ? 'center' : 'flex-start'}
        >
          {!isMobile && !isTablet && (
            <Image
              $src={documentationTableExample}
              $width={isDesktop ? '45vw' : '100%'}
              $alt={alt}
            />
          )}
          <StyledModernCompatibilityCol $align={'stretch'}>
            {(isMobile || isTablet) && (
              <Image $src={documentationTableExample} $width="100%" $alt={alt} />
            )}
            <CompatibilityDescription />
          </StyledModernCompatibilityCol>
        </StyledModernCompatibilityRow>
      </StyledLandingContainer>
    </StyledLandingPageSection>
  );
};
