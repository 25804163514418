import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledAdjustableRow } from '@/shared/styles';
import { Image } from '@/shared/components/Image/Image';
import u1ToolsetImg from '@/assets/images/landingPage/eaa/u1Toolset.png';
import {
  StyledLandingContainer,
  StyledLandingPageSection,
  StyledLandingPurpleTitle,
  StyledLandingText,
} from '../../../LandingPages.styles';
import { StyledTextContainer } from './U1Toolset.styles';

export const U1Toolset: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isDesktop, isWideDesktop, isTablet } = useDeviceDetect();

  return (
    <StyledLandingPageSection>
      <StyledLandingContainer $size={'md'}>
        <StyledAdjustableRow
          $gap={!isMobile ? 40 : 32}
          $align={isDesktop || isWideDesktop ? 'center' : 'start'}
          $wrap={isDesktop || isWideDesktop ? 'nowrap' : 'wrap'}
        >
          <StyledTextContainer>
            <StyledLandingPurpleTitle $visualSizeLevel={isMobile ? 4 : 2} level={2}>
              {t('landing_page.eaa.toolset.title')}
            </StyledLandingPurpleTitle>
            <StyledLandingText>{t('landing_page.eaa.toolset.content1')}</StyledLandingText>
            <StyledLandingText>{t('landing_page.eaa.toolset.content2')}</StyledLandingText>
          </StyledTextContainer>
          {!isMobile && (
            <Image
              $src={u1ToolsetImg}
              $width={isDesktop || isWideDesktop ? 'auto' : '100%'}
              $flex={isDesktop ? '1' : '0 0 auto'}
            />
          )}
        </StyledAdjustableRow>
      </StyledLandingContainer>
    </StyledLandingPageSection>
  );
};
