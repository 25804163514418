import styled from 'styled-components';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledAdjustableRow } from '@/shared/styles';

export const EnterpriseDesktopIntro = styled(StyledAdjustableRow)`
  ${() => {
    const { isMobile, isTablet, isWideDesktop, isDesktop } = useDeviceDetect();

    return `
      flex-direction: ${isWideDesktop || isDesktop ? 'row' : 'column'} !important;
      justify-content: ${isWideDesktop || isDesktop ? 'space-between' : 'stretch'} !important;
      padding-top: ${isMobile ? '40px' : '80px'};
      gap: ${isMobile || isTablet ? '40px' : '20px'};
    `;
  }}
`;

export const EnterpriseDesktopIntroImage = styled.div`
  ${() => {
    const { isMobile } = useDeviceDetect();

    return `
      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 100px;
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: ${isMobile ? '80%' : '100%'};
      `;
  }}
`;

export const EnterpriseDesktopIntroLabel = styled.div`
  height: 70px;
  margin: 16px 0px 0px 16px;
  z-index: 2;
  border-radius: 16px;
  border: 2px solid ${(props) => props.theme.overlayTextBorder};
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  ${() => {
    const { isMobile } = useDeviceDetect();

    return `
      width: ${isMobile ? '180px' : '204px'};
      ${
        isMobile &&
        `
          margin: auto -2px -20px auto;
          border-right: none;
          border-radius: 24px 0 0 24px;
          `
      }
    `;
  }}
`;
