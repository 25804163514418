import { WindowDimensions, ScreenBreakpoint } from './WindowDimensions.consts';

export class BreakpointHandler {
  width!: number;
  height!: number;

  constructor({ width, height }: WindowDimensions) {
    this.width = width;
    this.height = height;
  }

  isMobile(): boolean {
    return this.width < ScreenBreakpoint.TABLET;
  }

  isTablet(): boolean {
    return this.width >= ScreenBreakpoint.TABLET && this.width < ScreenBreakpoint.DESKTOP;
  }

  isDesktop(): boolean {
    return this.width >= ScreenBreakpoint.DESKTOP && this.width < ScreenBreakpoint.WIDE_DESKTOP;
  }

  isWideDesktop(): boolean {
    return this.width >= ScreenBreakpoint.WIDE_DESKTOP;
  }
}
