import useDeviceDetect from '@/hooks/useDeviceDetect';
import { OurFeatures } from '../../../Common/Features/Features';
import { eaaBgGradients } from '../../../LandingPages.consts';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';

export const OurFeaturesEAA: React.FC = () => {
  const { isMobile } = useDeviceDetect();
  const isDarkTheme = useThemeStore((state) => state.isDarkMode);

  return (
    <OurFeatures
      $bgColor={isDarkTheme ? eaaBgGradients.features : undefined}
      $paddingTop={isMobile ? 80 : 140}
      $paddingRight={0}
      $paddingLeft={0}
    />
  );
};
