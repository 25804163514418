import { ActiveFrameworks } from '@/stores/Stores.consts';
import {
  FRAMEWORKS,
  FrameworkName,
  LOCALES,
  Modifications_Code,
  NUM_OF_DAYS,
  RES_STATUS,
} from './consts';
import { Modification, Options, ProductData, SubscriptionData } from './dto';
import { ErrorProperty } from './props';
import { _axios } from '../services/apiClient';
import { AxiosResponse } from 'axios';
import { KeyboardEvent, MutableRefObject } from 'react';
import { CheckboxGroupProps } from 'antd/es/checkbox';
import ISO6391 from 'iso-639-1';
import i18n from '@/services/localization';
import { defaultLanguage } from '@/stores/LangStore/LangStore.consts';

export const getRemainingDays = (expiryDate: Date): number => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  expiryDate.setHours(0, 0, 0, 0);
  const difference = expiryDate.getTime() - today.getTime();
  const remainingDays = Math.floor(difference / (1000 * 3600 * 24));
  return Math.max(remainingDays, 0);
};

export const getNumericDateTemplate = (date: Date, locale?: string): string => {
  return date
    .toLocaleString(locale ?? LOCALES.EN_US, {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    })
    .split('/')
    .join('.');
};

export const getDateTemplate = (date: Date, locale?: string): string => {
  return date.toLocaleString(locale ?? LOCALES.MONTH_DAY_YEAR, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

export const getRenewDate = (date: Date): string => {
  const afterExpiry = new Date(date);
  afterExpiry.setDate(date.getDate() + NUM_OF_DAYS.ONE_DAY);
  const hasDaysLeft = getRemainingDays(date) > 0;
  const renewDate = hasDaysLeft ? afterExpiry : new Date();
  return getDateTemplate(renewDate, LOCALES.DAY_MONTH_YEAR);
};

export const getOrdinals = (num: number | string): string => {
  switch (num) {
    case 1:
    case '1':
      return 'st';
    case 2:
    case '2':
      return 'nd';
    case 3:
    case '3':
      return 'rd';
    default:
      return 'th';
  }
};

export const getPostTrialDate = () => {
  const postTrial = new Date();
  postTrial.setDate(postTrial.getDate() + NUM_OF_DAYS.TWO_WEEKS);
  const day = postTrial.toLocaleString('en-GB', {
    day: 'numeric',
  });
  const ordinals = getOrdinals(day);
  const month = postTrial.toLocaleString('en-GB', {
    month: 'long',
  });
  return `${day}${ordinals} of ${month}`;
};

export const getDaysInCurrentMonth = () => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;
  const daysInCurrentMonth = new Date(currentYear, currentMonth, 0).getDate();
  return daysInCurrentMonth;
};

export const getCapitalizedFrameworks = (frameworks?: ActiveFrameworks[] | string[]): string[] => {
  return (
    frameworks?.map((fw) => {
      const fwName = typeof fw === 'string' ? fw : fw.name;
      return FRAMEWORKS[fwName];
    }) ?? []
  );
};

export const toTitleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const extractErrors = (response?: AxiosResponse<any, any>) => {
  const errProperties: ErrorProperty[] = response?.data?.properties;
  let errStr = '';
  if (response?.status === RES_STATUS.UNPROCESSABLE) {
    errProperties?.forEach((prop) => {
      errStr += `${prop.messages.map((msg) => {
        return `${msg}. `;
      })}\n`;
    });
  } else if (response?.status === RES_STATUS.BAD_REQUEST) {
    errStr = response?.data.message;
  } else {
    errStr = 'An error occured. Please try again later.';
  }
  return errStr;
};

export const getPriceFormat = (price: number | string): string => {
  const formattedPrice = typeof price === 'number' ? price.toLocaleString(LOCALES.EN_US) : price;
  return formattedPrice;
};

export const getFrameworkIcons = (frameworks?: ActiveFrameworks[]) => {
  return frameworks?.map((fw) => {
    return fw.icon;
  });
};

export const getPricing = (
  interval: 'MONTH' | 'YEAR' | 'ONETIME',
  prod?: ProductData | Modification
) => {
  return prod?.prices?.find((p) => p.interval === interval)?.value ?? 0;
};

export const getModification = (
  prod: ProductData | SubscriptionData,
  modification: Modifications_Code
) => {
  return prod.modifications.find((mod) => mod.code === modification);
};

export const getActiveFrameworks = (frameworks?: Modification) => {
  const supportedFrameworks: ActiveFrameworks[] = [];
  const activeFW = (frameworks?.options as Options[]).filter((op) => op.status === 'ACTIVE');
  activeFW.forEach((fw) =>
    supportedFrameworks.push({ name: fw.code, icon: fw.icon as string, title: fw.title })
  );
  return supportedFrameworks;
};

export const getRequiredFieldsDic = (frameworks?: Modification) => {
  const requiredFields: Record<string, string[]> = {};
  (frameworks?.options as Options[]).forEach((op) => {
    if (op.requiredFields) requiredFields[op.code] = op.requiredFields;
  });
  return requiredFields;
};

export const getIconByFramework = (frameworks: ActiveFrameworks[], frameworkName: string) =>
  frameworks.find((framework) => framework.name.toLowerCase().includes(frameworkName.toLowerCase()))
    ?.icon || '';

export const downloadItem = (query: string) => {
  _axios.get(query, { responseType: 'arraybuffer' }).then((res) => {
    const type = res.headers['content-type'];
    const extension = type?.split('/')[1];
    const fileName =
      res.headers['content-disposition']?.split('filename="')[1].split('"')[0] ??
      `default-filename.${extension}`;
    const blob = new Blob([res.data], { type });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  });
};

export const capitalizeStr = (str: string): string => {
  return str?.toLowerCase().replace(/\b\w/g, (firstChar: string) => {
    return firstChar.toUpperCase();
  });
};

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const blockNanValues = (e: KeyboardEvent<HTMLInputElement>) => {
  const keyCode = Number(e.key);
  if (isNaN(keyCode) && e.key !== 'Backspace' && e.key !== 'Tab') {
    e.preventDefault();
  }
};

export const getFrameworksWithComma = (frameworks: string[] | CheckboxGroupProps[]): string => {
  let finalText = '';
  frameworks.forEach((fw, i) => {
    finalText += FrameworkName[fw as string];
    if (i < frameworks.length - 1) {
      finalText += ', ';
    }
  });
  return finalText;
};

export const isAllPropsUndefined = (obj: any) => {
  return Object.values(obj).every((value) => value === undefined);
};

export const hexToRgb = (hex: string) => {
  hex = hex.replace(/^#/, '');
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `${r}, ${g}, ${b}`;
};

export const isValidLanguage = (language: string): boolean => {
  return /^[a-z]{2}$/.test(language) && ISO6391.validate(language);
};

export const getPathWithoutLang = (path: string) => {
  return path.replace(/^\/[a-z]{2}(\/|$)/i, '/');
};

export const detectLanguageFromState = (state: string | null): string | null => {
  if (state) {
    const decodedState = JSON.parse(decodeURIComponent(state));
    if (decodedState.lang) {
      return decodedState.lang;
    }
  }
  return null;
};

export const isBot = (userAgent: string): boolean => {
  return /Googlebot|Bingbot|Slurp|DuckDuckBot|Baiduspider|YandexBot|facebookexternalhit/i.test(
    userAgent
  );
};

export const isStringifiedArray = (str: string) => {
  try {
    const noLineBreaksStr = str.replace(/\r\n/g, '');
    const parsedValue = JSON.parse(noLineBreaksStr);
    return Array.isArray(parsedValue);
  } catch (error) {
    return false;
  }
};

export const scrollToRef = (ref: MutableRefObject<any>) => {
  ref?.current &&
    (ref.current as HTMLElement).scrollIntoView({
      behavior: 'smooth',
    });
};

export const scrollToTop = () => {
  const scrollbarElement = document.querySelector('.u1-scrollbar>div:not([aria-hidden])');
  if (scrollbarElement) {
    scrollbarElement.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

export const getLanguage = () => {
  let language: string | null = location.pathname.split('/')[1];
  const stateParam = new URLSearchParams(location.search).get('state');

  if (stateParam) {
    language = detectLanguageFromState(stateParam);
  }

  return language && isValidLanguage(language) ? language : 'en';
};

export const buildLangPath = (relativePath: string, language?: string) => {
  if (language) {
    language = language === defaultLanguage ? '' : language;
  } else {
    language = i18n.language && i18n.language !== defaultLanguage ? i18n.language : '';
  }
  return [
    '/',
    language, // language detection
    relativePath,
  ]
    .join('/')
    .replace(/\/+/g, '/');
};
