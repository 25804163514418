import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { darkTheme } from '@/shared/themes/darkTheme';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import { THEME_ICONS } from '@/shared/consts';
import { Trusted } from '../../../Common/Trusted/Trusted';
import { eaaBgGradients } from '../../../LandingPages.consts';

export const TrustedEnterprises: React.FC = () => {
  const { t } = useTranslation();
  const isDarkTheme = useThemeStore((state) => state.isDarkMode);
  const { isMobile } = useDeviceDetect();

  return (
    <ThemeProvider theme={darkTheme}>
      <Trusted
        $marginBottom={isMobile ? -81 : -141}
        $paddingTop={isDarkTheme ? 0 : 80}
        $paddingBottom={80}
        $bgColor={eaaBgGradients.trusted}
        $title={t('landing_page.eaa.trusted.title')}
        $content={t('landing_page.eaa.trusted.content')}
        $iconsColor={THEME_ICONS.WHITE}
      />
    </ThemeProvider>
  );
};
