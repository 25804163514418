import { scrollToTop } from '@/shared/utils';
import { StyledFooterLinkNoStyle } from '../Footer.styles';
import { FooterLinksSectionProps } from './FooterSections.consts';

export const FooterLink: React.FC<FooterLinksSectionProps> = ({
  name,
  to,
}: FooterLinksSectionProps) => {
  return (
    <StyledFooterLinkNoStyle aria-label={name} to={to} onClick={scrollToTop}>
      {name}
    </StyledFooterLinkNoStyle>
  );
};
