import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledRowOffset } from '@/shared/styles';
import styled from 'styled-components';

export const StyledEAAIntro = styled(StyledRowOffset)`
  ${() => {
    const { isMobile, isTablet, isDesktop } = useDeviceDetect();

    return `
      gap: 50px;
      padding: ${isMobile ? '40px 0px 80px' : '80px 0px'};

      ${
        (isTablet || isMobile) &&
        `
        flex-wrap: wrap;
        justify-content: center;
      `
      }

      img {
        object-fit: contain;
        max-width: ${isMobile || isTablet ? '768px' : isDesktop ? '610px' : '710px'};
      }
    }`;
  }}
`;
