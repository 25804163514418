import React from 'react';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import { Icon } from '@/shared/components/Icon';
import { THEME_ICONS } from '@/shared/consts';
import { getTrustedCompaneis } from './Trusted.consts';
import { StyledTrustedCompaniesGrid, StyledTrustedCompaniesLogo } from './Trusted.styles';

export const LogosGrid: React.FC = () => {
  const isDarkMode = useThemeStore((state) => state.isDarkMode);
  const themeIcons = isDarkMode ? THEME_ICONS.WHITE : THEME_ICONS.DARK;

  return (
    <StyledTrustedCompaniesGrid>
      {getTrustedCompaneis(themeIcons).map((comp) => (
        <StyledTrustedCompaniesLogo key={comp.src}>
          <Icon $src={comp.src} $alt={comp.alt} $size={100} $loading="lazy" />
        </StyledTrustedCompaniesLogo>
      ))}
    </StyledTrustedCompaniesGrid>
  );
};
