import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { Icon } from '@/shared/components/Icon';
import iso from '@/assets/images/landingPage/footer/iso.svg';
import { User1stDetails } from '../User1stDetails';
import { StyledFooterList, StyledFooterCol, StyledFooterTitle } from '../Footer.styles';
import { mobilePlatforms, webPlatforms } from '../Footer.consts';
import { LinksSection } from './Sections/LinksSection';
import { About } from './Sections/About';
import { MobileIcons } from './Sections/MobileIcons';
import { compliances } from '../../TabsInfo/Compliance/Compliance.consts';
import { Certifications } from './Sections/Certifications';

export const FooterSections: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isWideDesktop } = useDeviceDetect();

  return (
    <StyledFooterList $direction={isMobile ? 'column' : 'row'}>
      {isWideDesktop && (
        <StyledFooterCol>
          <User1stDetails />
        </StyledFooterCol>
      )}
      <StyledFooterCol>
        <StyledFooterTitle $visualSizeLevel={1} level={2}>
          {t('landing_page.footer.complience.title')}
        </StyledFooterTitle>
        <LinksSection links={compliances} />
      </StyledFooterCol>
      <StyledFooterCol>
        <StyledFooterTitle $visualSizeLevel={1} level={2}>
          {t('landing_page.footer.certifications.title')}
        </StyledFooterTitle>
        <Certifications />
        <Icon $src={iso} $maxWidth={190} $alt="iso 27018:2019, iso 27001, iso 2707:2015, W3C" />
      </StyledFooterCol>
      <StyledFooterCol>
        <StyledFooterTitle $visualSizeLevel={1} level={2}>
          {t('landing_page.footer.web_platforms.title')}
        </StyledFooterTitle>
        <LinksSection links={webPlatforms} />
      </StyledFooterCol>
      <StyledFooterCol>
        <StyledFooterTitle $visualSizeLevel={1} level={2}>
          {t('landing_page.footer.mobile_platforms.title')}
        </StyledFooterTitle>
        <LinksSection links={mobilePlatforms} />
        <MobileIcons />
      </StyledFooterCol>
      <StyledFooterCol>
        <StyledFooterTitle $visualSizeLevel={1} level={2}>
          {t('landing_page.footer.about.title')}
        </StyledFooterTitle>
        <About />
      </StyledFooterCol>
    </StyledFooterList>
  );
};
