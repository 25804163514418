import { Drawer } from 'antd';
import styled from 'styled-components';
import { StyledPinkRouterLink } from '../Link/Links.styles';

export const StyledCookieBanner = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: row;
  }

  [data-component] {
    display: flex;
    align-items: center;
    padding: 10px 20px;
  }

  .ant-drawer-content {
    background: ${(props) => props.theme.backgroundColor};
  }

  .ant-drawer-title {
    color: ${(props) => props.theme.textColor};
  }

  .ant-drawer-header {
    border-bottom-color: ${(props) => props.theme.marginsBorder};
  }

  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }

  .anticon {
    color: ${(props) => props.theme.textColor};
  }
`;

export const StyledBannerRouterLink = styled(StyledPinkRouterLink)`
  padding: 10px 20px;
`;
