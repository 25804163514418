import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledCol } from '@/shared/styles';
import {
  StyledFeaturesContainer,
  StyledLandingContainer,
  StyledLandingPageSection,
  StyledLandingPurpleTitle,
} from '../../LandingPages.styles';
import { Icon } from '@/shared/components/Icon';
import { StyledFeatureCard, StyledFeatureText, StyledFeatureTitle } from './Features.styles';
import { featuresImages } from './Features.consts';
import { TitleColorProps } from '../../LandingPages.consts';

export const OurFeatures: React.FC<TitleColorProps> = (props: TitleColorProps) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();

  return (
    <StyledLandingPageSection {...props}>
      <StyledLandingContainer $size={'md'}>
        <StyledCol $gap={40}>
          <StyledLandingPurpleTitle
            $visualSizeLevel={isMobile ? 4 : 2}
            level={2}
            $align={isMobile ? 'start' : 'center'}
            $hasBackground={!!props.$bgColor}
          >
            {t('landing_page.features.title')}:
          </StyledLandingPurpleTitle>
          <StyledFeaturesContainer>
            {featuresImages.map((img, i) => (
              <StyledFeatureCard key={`features${i}`}>
                <Icon $src={img} $size={32} />
                <StyledCol $gap={8}>
                  <StyledFeatureTitle level={3} $visualSizeLevel={isMobile || isTablet ? 1 : 3}>
                    {t(`landing_page.features.block${i + 1}.title`)}:
                  </StyledFeatureTitle>
                  <StyledFeatureText fontSize={isMobile || isTablet ? '16px' : '14px'}>
                    {t(`landing_page.features.block${i + 1}.content`)}
                  </StyledFeatureText>
                </StyledCol>
              </StyledFeatureCard>
            ))}
          </StyledFeaturesContainer>
        </StyledCol>
      </StyledLandingContainer>
    </StyledLandingPageSection>
  );
};
