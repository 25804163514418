import React from 'react';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { useTranslation } from 'react-i18next';
import { StyledCol } from '@/shared/styles';
import { SolutionsCards } from './SolutionsCards';
import { StyledLandingPurpleTitle, StyledLandingText } from '../../../LandingPages.styles';
import { StyledCompatibilityRow } from './ModernCompatibility.styles';
import { solutionsCardsTexts } from './ModernCompatibility.consts';

export const CompatibilityDescription: React.FC = () => {
  const { isMobile, isDesktop } = useDeviceDetect();
  const { t } = useTranslation();

  return (
    <StyledCol $gap={16}>
      <StyledLandingPurpleTitle $visualSizeLevel={isMobile ? 4 : 2} level={2}>
        {t('landing_page.enterprise.compatibility.title')}
      </StyledLandingPurpleTitle>
      <StyledLandingText fontSize={'18px'}>
        {t('landing_page.enterprise.compatibility.content1')}
      </StyledLandingText>
      <StyledLandingText fontSize={'18px'}>
        {t('landing_page.enterprise.compatibility.content2')}
      </StyledLandingText>
      <StyledCompatibilityRow
        $gap={isDesktop ? 10 : 24}
        $wrap={isMobile ? 'wrap' : 'nowrap'}
        $align="center"
      >
        {solutionsCardsTexts.map((cardText, i) => (
          <SolutionsCards text={cardText} key={`card-${i}`} />
        ))}
      </StyledCompatibilityRow>
    </StyledCol>
  );
};
