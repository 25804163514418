import React from 'react';
import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledCol, StyledPurpleText } from '@/shared/styles';
import { StyledHybridTitle, StyledLandingText } from '../../../LandingPages.styles';
import { DemoModal } from '../../../Common/DemoModal/DemoModal';

export const IntroContent: React.FC = () => {
  const { isMobile, isTablet, isWideDesktop } = useDeviceDetect();
  const { t } = useTranslation();

  return (
    <StyledCol $gap={32} $maxWidth={isMobile || isTablet ? '100%' : '740px'}>
      <StyledCol $gap={16}>
        <StyledHybridTitle
          level={2}
          $break={false}
          $align={'start'}
          $visualSizeLevel={isMobile ? 2 : 1}
        >
          {t('landing_page.enterprise.intro.title.normal')}
          {isWideDesktop ? <br /> : ' '}
          <StyledPurpleText fontSize={'inherit'}>
            {t('landing_page.enterprise.intro.title.gradient')}
          </StyledPurpleText>
        </StyledHybridTitle>
        <StyledLandingText textAlign={'start'} fontSize={'18px'}>
          {t('landing_page.enterprise.intro.content')}
        </StyledLandingText>
      </StyledCol>
      <DemoModal
        $btnText={t('landing_page.request_demo')}
        $btnWidth={!isMobile && !isTablet ? 'fit-content' : '100%'}
      />
    </StyledCol>
  );
};
