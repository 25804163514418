import React, { useEffect } from 'react';
import { PolicySection } from './PolicySection/PolicySection';
import { StyledPolicyContainer } from './Policies.styles';
import { StyledCol } from '@/shared/styles';
import { PolicyContent, PolicySectionProps } from './PolicySection/PolicySection.consts';
import { usePolicies } from '@/hooks/Policies/usePolicies';
import { PolicyType } from '@/hooks/Policies/Policies.consts';
import { useTranslation } from 'react-i18next';

export const PrivacyPolicy: React.FC = () => {
  const { i18n } = useTranslation();
  const { getPolicy, policyData } = usePolicies();

  useEffect(() => {
    getPolicy({
      params: { type: PolicyType.PRIVACY_POLICY },
    });
  }, [getPolicy, i18n.language]);

  return (
    policyData && (
      <StyledPolicyContainer>
        <PolicySection $flex="center" title={policyData.title} />
        <StyledCol $gap={100}>
          {policyData.content.map((item: PolicySectionProps) => (
            <StyledCol $gap={20} key={item.title}>
              <PolicySection titleLevel={2} $flex="center" title={item.title} />
              {(item.content as PolicyContent[])?.map((item2: PolicyContent) => (
                <PolicySection
                  key={item2.title}
                  title={item2.title}
                  content={item2.sections}
                  titleLevel={3}
                />
              ))}
            </StyledCol>
          ))}
        </StyledCol>
      </StyledPolicyContainer>
    )
  );
};
