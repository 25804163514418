import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import a11yImportanceImg from '@/assets/images/landingPage/a11yImportanceImg.png';
import { Image } from '@/shared/components/Image/Image';
import { ImportanceDescription } from './ImportanceDescription';
import { StyledImportance, StyledImportanceCol } from './Importance.styles';
import {
  StyledLandingContainer,
  StyledLandingPageSection,
  StyledLandingPurpleTitle,
} from '../../../LandingPages.styles';

export const Importance: React.FC = () => {
  const { isMobile } = useDeviceDetect();
  const { t } = useTranslation();

  return (
    <StyledLandingPageSection>
      <StyledLandingContainer $size={'md'}>
        <StyledImportance $gap={isMobile ? 32 : 40}>
          <StyledImportanceCol>
            <StyledLandingPurpleTitle $visualSizeLevel={isMobile ? 4 : 2} level={2}>
              {t('landing_page.importance.title')}
            </StyledLandingPurpleTitle>
            {isMobile && <Image $src={a11yImportanceImg} $width="100%" />}
            <ImportanceDescription />
          </StyledImportanceCol>
          {!isMobile && <Image $src={a11yImportanceImg} $width="100%" />}
        </StyledImportance>
      </StyledLandingContainer>
    </StyledLandingPageSection>
  );
};
