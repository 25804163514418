import { useWindowBreakpoints } from './WindowDimensions/useWindowBreakpoints';
import { BreakpointHandler } from './WindowDimensions/BreakpointHandler';

const useDeviceDetect = () => {
  const breakpoints = useWindowBreakpoints((handler: BreakpointHandler) => ({
    isMobile: handler.isMobile(),
    isTablet: handler.isTablet(),
    isDesktop: handler.isDesktop(),
    isWideDesktop: handler.isWideDesktop(),
  }));

  return breakpoints;
};

export default useDeviceDetect;
