import styled from 'styled-components';
import { Carousel } from 'antd';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledRow } from '@/shared/styles';
import { LogoSliderProps } from './Trusted.consts';

export const StyledSlider = styled.div<LogoSliderProps>`
  position: relative;

  ${(props) => {
    const { isMobile } = useDeviceDetect();
    const { $isStretched } = props;

    return `.slick-list {
      overflow: ${$isStretched || isMobile ? 'visible !important' : 'hidden !important'};
    }
    .slick-track {
      display: flex !important;
      padding-bottom: ${isMobile ? '0' : '50px'};
    }`;
  }}
`;

export const StyledCarousel = styled(Carousel)`
  .slick-slide {
    flex: 0 0 auto;
  }

  .slick-slide > div {
    display: flex !important;
    justify-content: center;
  }

  .slick-dots li button {
    background: ${(props) => props.theme.textColor} !important;
  }
`;

export const StyledSlideRow = styled.div`
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  gap: 50px;
  width: 70% !important;
  margin: 50px 0px;

  ${() => {
    const { isMobile } = useDeviceDetect();

    return isMobile
      ? `
      img {
        max-width: 85px;
        max-height: 60px;
      }`
      : `
      img {
        width: 7rem;
      }`;
  }}
`;

export const StyledPlayBtnsContainer = styled(StyledRow)`
  position: absolute;
  right: 0;
  bottom: 0;
  justify-content: end;

  svg {
    color: ${(props) => props.color ?? props.theme.textColor};
    width: 23px;
    height: 23px;
  }
`;

export const StyledSlide = styled.div`
  flex: 0 0 auto;
  padding: 0 30px;
  height: 130px;
  display: flex !important;
  align-items: center;
  justify-content: center;
`;
