import useDeviceDetect from '@/hooks/useDeviceDetect';
import { U1Button } from '@/shared/components/Button/U1Button';
import { ContactUsModal } from '@/shared/components/Modals/ContactUsModal/ContactUsModal';
import { DemoModalProps } from './DemoModal.consts';

export const DemoModal: React.FC<DemoModalProps> = ({
  $btnText,
  $refName,
  $btnWidth,
  $btnMinWidth,
  $btnMargin,
}: DemoModalProps) => {
  const { isMobile } = useDeviceDetect();

  return (
    <ContactUsModal
      refName={`${$refName ?? `contact-us-intro`}`}
      trigger={
        <U1Button
          text={$btnText}
          $width={$btnWidth ?? (isMobile ? '100%' : 'fit-content')}
          $padding="8px 24px"
          $minWidth={$btnMinWidth ?? 'auto'}
          $margin={$btnMargin ?? '0'}
        />
      }
    />
  );
};
