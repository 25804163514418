import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledCol } from '@/shared/styles';
import prodsPreview from '@/assets/images/landingPage/prodsPreview.png';
import { useLangStore } from '@/stores/LangStore/LangStore';
import {
  StyledLeftCol,
  StyledOurSolution,
  StyledOurSolutionRow,
  StyledRightColImg,
} from './OurSolution.styles';
import {
  StyledLandingContainer,
  StyledLandingPageSection,
  StyledLandingPurpleSubtitle,
  StyledLandingPurpleTitle,
  StyledLandingText,
} from '../../../LandingPages.styles';

export const OurSolution: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useDeviceDetect();
  const isRtl = useLangStore((state) => state.isRtl());

  return (
    <StyledLandingPageSection>
      <StyledLandingContainer $size={'md'}>
        <StyledOurSolution $gap={isMobile ? 32 : 40}>
          <StyledLandingPurpleTitle $visualSizeLevel={isMobile ? 4 : 2} level={2} $align="center">
            {t('landing_page.our_solution.title')}
          </StyledLandingPurpleTitle>
          <StyledOurSolutionRow
            $justify={isRtl ? 'flex-end' : 'space-between'}
            $width="100%"
            $gap={isMobile ? 32 : 40}
          >
            <StyledLeftCol $gap={isMobile ? 24 : 32}>
              {[...Array(3)].map((_, i) => (
                <StyledCol key={`solution${i}`} $gap={isMobile ? 8 : 16}>
                  <StyledLandingPurpleSubtitle
                    $visualSizeLevel={1}
                    level={3}
                    $align={isRtl ? 'right' : 'left'}
                    $width={isRtl ? 'fit-content' : '100%'}
                  >
                    {t(`landing_page.our_solution.block${i + 1}.title`)}:
                  </StyledLandingPurpleSubtitle>
                  <StyledLandingText fontSize={isMobile ? '16px' : '18px'}>
                    {t(`landing_page.our_solution.block${i + 1}.content`)}
                  </StyledLandingText>
                </StyledCol>
              ))}
            </StyledLeftCol>
            <StyledRightColImg src={prodsPreview} />
          </StyledOurSolutionRow>
        </StyledOurSolution>
      </StyledLandingContainer>
    </StyledLandingPageSection>
  );
};
