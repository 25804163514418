import styled from 'styled-components';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledPurpleText } from '@/shared/styles';
import {
  LandingPageProps,
  LandingPageSectionProps,
  LandingPageContainerProps,
} from './LandingPages.consts';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import { StyledSubTitle, StyledMainTitle } from '../BeforeLogin.styles';
import { MainTitleProps } from '../BeforeLogin.consts';
import { TextProps } from '@/shared/props';

export const StyledLandingPage = styled.main<LandingPageProps>`
  ${(props) => {
    const { isMobile } = useDeviceDetect();
    const { $allowGaps } = props;

    return `
      margin-bottom: ${isMobile ? '80px ' : '140px'};
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      font-family: 'U1Poppins', sans-serif;
      letter-spacing: 0;
      overflow: hidden;
      ${$allowGaps && `gap: ${isMobile ? '80' : '140'}px;`}
    `;
  }}

  img {
    top: 0px;
    right: 0px;
  }
`;

export const StyledHybridTitle = styled(StyledMainTitle)<{ $break?: boolean }>`
  width: fit-content;
  gap: 8px;
  ${(props) =>
    props.$break &&
    `
    display: flex;
    flex-direction: column;
    `}
`;

export const StyledLandingPurpleTitle = styled(StyledMainTitle)<MainTitleProps>`
  ${(props) =>
    useThemeStore.getState().isDarkMode && props.$hasBackground
      ? `color: ${props.theme.textColor} !important;`
      : `color: ${props.theme.purpleText} !important;`}
`;

export const StyledLandingPurpleSubtitle = styled(StyledSubTitle)<MainTitleProps>`
  color: ${(props) => props.theme.purpleText} !important;
`;

export const StyledWhiteTitle = styled(StyledMainTitle)<MainTitleProps>`
  color: ${(props) => props.theme.lpText} !important;
  font-weight: ${(props) => props.$weight ?? '600'};
  font-size: ${(props) => props.$size ?? '32px'};
  align-self: ${(props) => (props.$flex ? `${props.$flex}` : `center`)} !important;
  word-break: keep-all;
`;

export const StyledLandingText = styled.div<TextProps & { $width?: string }>`
  font-size: ${(props) => props.fontSize ?? '16px'};
  font-weight: 400;
  color: ${(props) => props.theme.textColor};
  ${(props) => props.$width && `width: ${props.$width}`};
  ${(props) => props.textAlign && `text-align: ${props.textAlign}`};
`;

export const StyledLandingPurpleText = styled(StyledPurpleText)<TextProps>``;

export const StyledFeaturesContainer = styled.div`
  display: grid;
  justify-items: center;

  ${() => {
    const { isMobile, isTablet } = useDeviceDetect();

    return `
      grid-template-columns: repeat(${isMobile || isTablet ? '1' : '3'}, 1fr);
      gap: ${isMobile ? '24' : '20'}px;
    `;
  }}
`;

export const StyledLandingPageSection = styled.div<LandingPageSectionProps>`
  ${(props) => props.$bgColor && ` background: ${props.$bgColor};`};

  ${(props) =>
    props.$bgImg &&
    `
    background-image: url(${props.$bgImg});
    background-repeat: no-repeat; 
    background-position: center;
    background-size: 100% 100%;
  `};

  ${(props) => props.$paddingTop && `padding-top: ${props.$paddingTop}px;`}
  ${(props) => props.$paddingBottom && `padding-bottom: ${props.$paddingBottom}px;`}
  ${(props) => props.$marginTop && `margin-top: ${props.$marginTop}px;`}
  ${(props) => props.$marginBottom && `margin-bottom: ${props.$marginBottom}px;`}
`;

export const StyledLandingContainer = styled.div<LandingPageContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  ${(props) => (props.$gap ? `gap: ${props.$gap}px` : '')};
  ${() => {
    const { isMobile } = useDeviceDetect();

    return isMobile ? 'padding: 0 16px' : 'padding: 0 24px';
  }};

  max-width: ${(props) => {
    const { isMobile, isTablet } = useDeviceDetect();
    const { $size } = props;

    switch ($size) {
      case 'lg':
        return isMobile ? '100%' : isTablet ? '1288px' : '1768px';
      case 'md':
        return isMobile ? '100%' : isTablet ? '1288px' : '1568px';
      case 'sm':
        return isMobile ? '100%' : isTablet ? '888px' : '1168px';
      case 'full':
        return '100%';
      default:
        return isMobile ? '100%' : isTablet ? '1288px' : '1568px';
    }
  }};
`;
