import styled from 'styled-components';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledAdjustableRow } from '@/shared/styles';

export const StyledComplianceAndExperience = styled(StyledAdjustableRow)`
  justify-content: space-between;
  flex-wrap: wrap;

  ${() => {
    const { isWideDesktop } = useDeviceDetect();

    return `
      flex-direction: ${isWideDesktop ? 'row' : 'column'}
    `;
  }}
`;

export const StyledComplianceImg = styled.img`
  width: 100%;
  object-fit: contain;
  width: 100%;
  flex: 0 0 auto;

  ${() => {
    const { isDesktop } = useDeviceDetect();

    return `
      width: ${isDesktop ? '350px' : '284px'}
    `;
  }}
`;

export const StyledAbsoluteImg = styled(StyledComplianceImg)`
  object-position: bottom;
  margin-bottom: -24px;

  ${() => {
    const { isDesktop } = useDeviceDetect();

    return `
      width: ${isDesktop ? '350px' : '284px'}
    `;
  }}
`;
