import styled from 'styled-components';
import { StyledCol } from '@/shared/styles';
import { StyledLandingPurpleSubtitle, StyledLandingText } from '../../LandingPages.styles';

export const StyledFeatureCard = styled(StyledCol)`
  gap: 16px;
  background: ${(props) => props.theme.sameColorBG};
  border: 2px solid ${(props) => props.theme.sameColorBorder};
  border-radius: 15px;
  padding: 24px;
  min-height: 254px;
`;

export const StyledFeatureTitle = styled(StyledLandingPurpleSubtitle)`
  color: ${(props) => props.theme.sameColorText} !important;
`;

export const StyledFeatureText = styled(StyledLandingText)`
  color: ${(props) => props.theme.sameColorText};
`;
