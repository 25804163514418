import { LinkProps } from '@/shared/props';
import { StyledLink, StyledLinkNoStyle } from './Links.styles';
import { v4 as uuidv4 } from 'uuid';

export const U1Link: React.FC<LinkProps> = ({
  text,
  href,
  styledLink = false,
  shouldOpenTab = false,
  excludeEl = false,
}: LinkProps) => {
  const props = { key: `${uuidv4()}`, 'data-u1-exclude': excludeEl, 'aria-label': text };
  const finalProps = shouldOpenTab ? { target: '_blank', ...props } : props;

  return styledLink ? (
    <StyledLink href={href} {...finalProps}>
      {text}
    </StyledLink>
  ) : (
    <StyledLinkNoStyle to={href ?? ''} {...finalProps} $isNavLink={!shouldOpenTab}>
      {text}
    </StyledLinkNoStyle>
  );
};
