import { InputProps } from '@/shared/props';
import { Input } from 'antd';
import styled from 'styled-components';

const { Search } = Input;

export const StyledSearch = styled(Search)<InputProps>`
  background: ${(props) => props.theme.inputColor};
  border: 1px solid ${(props) => props.theme.inputBorder};
  border-radius: 5px;

  .ant-input-wrapper {
    display: flex;
    border: 1px solid ${(props) => props.theme.inputBorder};
  }

  .ant-input {
    border: transparent;
    border-radius: 5px;
    background: ${(props) => props.theme.inputColor};
    padding: 13px 12px 13px 0px;
    gap: 12px;
    order: 2;
    color: ${(props) => props.theme.textColor} !important;
  }

  &&&& .ant-input:hover {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .ant-input::placeholder {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.textColor};
  }

  .ant-input-group-addon {
    background: ${(props) => props.theme.inputColor};
    float: start;
    order: 1;
    display: contents;
  }
  .ant-input-search-button {
    height: auto;
    border-color: transparent;
    background: ${(props) => props.theme.inputColor};
  }

  .ant-input-search-button > img {
    display: inline-flex;
    margin-left: -5px;
  }

  width: ${(props) => props.$width ?? `100%`};
`;
