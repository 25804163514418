import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import introductionImg from '@/assets/images/landingPage/eaa/eaaIntroduction.png';
import introductionMobile from '@/assets/images/landingPage/eaa/introductionMobile.png';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import { TextAndBanner } from '../../../Common/TextAndBanner/TextAndBanner';
import { eaaBgGradients } from '../../../LandingPages.consts';
import { StyledLandingPageSection, StyledLandingPurpleTitle } from '../../../LandingPages.styles';

export const Introduction: React.FC = () => {
  const { t } = useTranslation();
  const isDarkTheme = useThemeStore((state) => state.isDarkMode);
  const { isMobile } = useDeviceDetect();

  return (
    <StyledLandingPageSection
      $bgColor={isDarkTheme ? eaaBgGradients.introduction : undefined}
      $paddingTop={isDarkTheme ? 0 : 80}
    >
      <TextAndBanner
        title={
          <StyledLandingPurpleTitle
            $visualSizeLevel={isMobile ? 4 : 2}
            level={2}
            $hasBackground={!!eaaBgGradients.introduction}
          >
            {t('landing_page.eaa.introduction.title')}
          </StyledLandingPurpleTitle>
        }
        leftText={t('landing_page.eaa.introduction.leftText')}
        rightText={t('landing_page.eaa.introduction.rightText')}
        bannerImg={isMobile ? introductionMobile : introductionImg}
      />
    </StyledLandingPageSection>
  );
};
