import React from 'react';
import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import ourClientsImg from '@/assets/images/landingPage/enterprise/ourClientsImg.png';
import ourClientsImgWhite from '@/assets/images/landingPage/enterprise/ourClientsImgWhite.png';
import { Image } from '@/shared/components/Image/Image';
import { StyledCol } from '@/shared/styles';
import { useThemeStore } from '@/stores/ThemeStore/ThemeStore';
import { StyledOurClientsRow } from './OurClients.styles';
import {
  StyledLandingContainer,
  StyledLandingPageSection,
  StyledLandingPurpleTitle,
  StyledLandingText,
} from '../../../LandingPages.styles';

export const OurClients: React.FC = () => {
  const isDarkTheme = useThemeStore((state) => state.isDarkMode);
  const { isMobile, isTablet } = useDeviceDetect();
  const { t } = useTranslation();
  const textAlign = 'start';

  return (
    <StyledLandingPageSection>
      <StyledLandingContainer $size={'md'}>
        <StyledOurClientsRow $gap={40}>
          <StyledCol
            $gap={16}
            $justify="center"
            $direction={isMobile || isTablet ? 'column' : 'row'}
          >
            <StyledLandingPurpleTitle
              $visualSizeLevel={isMobile ? 4 : 2}
              level={2}
              $align={textAlign}
            >
              {t('landing_page.enterprise.clients.title')}
            </StyledLandingPurpleTitle>
            <StyledLandingText fontSize={'18px'} textAlign={textAlign}>
              {t('landing_page.enterprise.clients.content1')}
            </StyledLandingText>
            <StyledLandingText fontSize={'18px'} textAlign={textAlign}>
              {t('landing_page.enterprise.clients.content2')}
            </StyledLandingText>
          </StyledCol>
          <Image $src={isDarkTheme ? ourClientsImg : ourClientsImgWhite} $width="100%" />
        </StyledOurClientsRow>
      </StyledLandingContainer>
    </StyledLandingPageSection>
  );
};
