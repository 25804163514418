import { useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { darkTheme } from '@/shared/themes/darkTheme';
import { StyledCol } from '@/shared/styles';
import { Image } from '@/shared/components/Image/Image';
import introImg from '@/assets/images/landingPage/eaa/IntroStarsImg.png';
import { StyledEAAIntro } from './Intro.styles';
import { DemoModal } from '../../../Common/DemoModal/DemoModal';
import { eaaBgGradients } from '../../../LandingPages.consts';
import {
  StyledLandingContainer,
  StyledLandingPageSection,
  StyledLandingPurpleSubtitle,
} from '../../../LandingPages.styles';
import { StyledMainTitle } from '@/Views/BeforeLogin/BeforeLogin.styles';

export const Intro: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDeviceDetect();
  const titleAlignment = 'start';

  return (
    <ThemeProvider theme={darkTheme}>
      <StyledLandingPageSection
        $bgColor={eaaBgGradients.intro}
        $marginBottom={isMobile ? -80 : -140}
      >
        <StyledLandingContainer $size={'md'}>
          <StyledEAAIntro>
            <StyledCol
              $gap={32}
              $justify="center"
              $maxWidth={isMobile || isTablet ? '100%' : '610px'}
            >
              <StyledCol $gap={8}>
                <StyledLandingPurpleSubtitle
                  $visualSizeLevel={isMobile ? 2 : 1}
                  level={1}
                  $weight={600}
                  $align={titleAlignment}
                >
                  {t('landing_page.eaa.intro.title')}
                </StyledLandingPurpleSubtitle>
                <StyledMainTitle
                  level={2}
                  $visualSizeLevel={isMobile ? 4 : 2}
                  $align={titleAlignment}
                >
                  {t('landing_page.eaa.intro.content')}
                </StyledMainTitle>
              </StyledCol>
              <DemoModal
                $btnText={t('landing_page.eaa.book_demo')}
                $btnWidth={isMobile || isTablet ? '100%' : 'fit-content'}
              />
            </StyledCol>

            <Image $src={introImg} $width={isMobile || isTablet ? '100%' : '40%'} />
          </StyledEAAIntro>
        </StyledLandingContainer>
      </StyledLandingPageSection>
    </ThemeProvider>
  );
};
