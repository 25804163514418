import React, { ReactElement, useState } from 'react';
import { ContactUsForm } from '@/shared/components/Forms/ContactUs/ContactUsForm';
import { StyledModal } from '../Modals.styles';
import { StyledCol, StyledSecondaryText, StyledTitle } from '@/shared/styles';
import { U1Dialog } from '@u1/react-a11y-hooks';
import { ComponentName } from '@u1/ui-library-mapper';
import { useTranslation } from 'react-i18next';
import { CustomTriggerProps } from '../Modals.consts';
import { useLangStore } from '@/stores/LangStore/LangStore';
import { useUserJourney } from '@/hooks/UserJourney/useUserJourney';
import { EmailSent } from './EmailSent';

export const ContactUsModal: React.FC<CustomTriggerProps> = ({
  trigger,
  pricingPage,
  refName,
}: CustomTriggerProps) => {
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalMsg, setModalMsg] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const { t } = useTranslation();
  const isRtl = useLangStore((state) => state.isRtl());
  const { sendGuestJourney } = useUserJourney();

  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    setModalMsg(t('form.email_sent'));
    setEmailSent(true);
    setConfirmLoading(true);
    sendGuestJourney();

    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  return (
    <U1Dialog name={ComponentName.CONTACT_US_DIALOG}>
      {React.cloneElement(trigger as ReactElement, {
        onClick: showModal,
        triggerRef: refName,
      })}
      <StyledModal
        data-a11y-ref={refName}
        open={open}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        footer={[]}
      >
        {!emailSent && (
          <StyledCol $gap={8}>
            <StyledTitle level={2} $size={18}>
              {pricingPage
                ? t('pricing.contact_us.title')
                : t('landing_page.contact_us_popup.title')}
            </StyledTitle>
            <StyledSecondaryText
              fontSize="16"
              fontWeight={100}
              textAlign={isRtl ? 'right' : 'left'}
            >
              {pricingPage
                ? t('pricing.contact_us.content')
                : t('landing_page.contact_us_popup.content')}
            </StyledSecondaryText>
          </StyledCol>
        )}
        {modalMsg && emailSent && <EmailSent />}
        {!emailSent && <ContactUsForm onOk={handleOk} />}
      </StyledModal>
    </U1Dialog>
  );
};
