import { PlatformInfo } from './PlatformInfo/WebPlatformsInfo';
import react from './PlatformInfo/react.json';
import angular from './PlatformInfo/angular.json';
import vue from './PlatformInfo/vue.json';
import js from './PlatformInfo/js.json';
import shopify from './PlatformInfo/shopify.json';
import wordpress from './PlatformInfo/wordpress.json';
import umbraco from './PlatformInfo/umbraco.json';
import android from './PlatformInfo/android.json';
import ios from './PlatformInfo/ios.json';
import flutter from './PlatformInfo/flutter.json';
import reactNative from './PlatformInfo/reactNative.json';
import { StyledTab } from '../TabsInfo.styles';

export enum PlatformType {
  WEB = 'Web',
  MOBILE = 'Mobile',
}

export type PlatformProps = {
  plafromType: PlatformType;
};

export const webPlatforms = [
  {
    key: 'react',
    label: <StyledTab u1-exclude="true">{react.name}</StyledTab>,
    children: <PlatformInfo info={react} pdfName="Web Solutions Whitepaper - PDF" />,
    to: '/web-platforms/react',
  },
  {
    key: 'angular',
    label: <StyledTab u1-exclude="true">{angular.name}</StyledTab>,
    children: <PlatformInfo info={angular} pdfName="Web Solutions Whitepaper - PDF" />,
    to: '/web-platforms/angular',
  },
  {
    key: 'vue-js',
    label: <StyledTab u1-exclude="true">{vue.name}</StyledTab>,
    children: <PlatformInfo info={vue} pdfName="Web Solutions Whitepaper - PDF" />,
    to: '/web-platforms/vue-js',
  },
  {
    key: 'js',
    label: <StyledTab u1-exclude="true">{js.name}</StyledTab>,
    children: <PlatformInfo info={js} pdfName="Web Solutions Whitepaper - PDF" />,
    to: '/web-platforms/js',
  },
  {
    key: 'shopify',
    label: <StyledTab u1-exclude="true">{shopify.name}</StyledTab>,
    children: <PlatformInfo info={shopify} pdfName="Web Solutions Whitepaper - PDF" />,
    to: '/web-platforms/shopify',
  },
  {
    key: 'wordpress',
    label: <StyledTab u1-exclude="true">{wordpress.name}</StyledTab>,
    children: <PlatformInfo info={wordpress} pdfName="Web Solutions Whitepaper - PDF" />,
    to: '/web-platforms/wordpress',
  },
  {
    key: 'umbraco',
    label: <StyledTab u1-exclude="true">{umbraco.name}</StyledTab>,
    children: <PlatformInfo info={umbraco} pdfName="Web Solutions Whitepaper - PDF" />,
    to: '/web-platforms/umbraco',
  },
];

export const mobilePlatforms = [
  {
    key: 'android',
    label: <StyledTab u1-exclude="true">{android.name}</StyledTab>,
    children: <PlatformInfo info={android} pdfName={`${android.name} Whitepaper - PDF`} />,
    to: '/mobile-platforms/android',
  },
  {
    key: 'ios',
    label: <StyledTab u1-exclude="true">{ios.name}</StyledTab>,
    children: <PlatformInfo info={ios} pdfName={`${ios.name} Whitepaper - PDF`} />,
    to: '/mobile-platforms/ios',
  },
  {
    key: 'flutter',
    label: <StyledTab u1-exclude="true">{flutter.name}</StyledTab>,
    children: <PlatformInfo info={flutter} pdfName={`${flutter.name} Whitepaper - PDF`} />,
    to: '/mobile-platforms/flutter',
  },
  {
    key: 'react-native',
    label: <StyledTab u1-exclude="true">{reactNative.name}</StyledTab>,
    children: <PlatformInfo info={reactNative} pdfName={`${reactNative.name} Whitepaper - PDF`} />,
    to: '/mobile-platforms/react-native',
  },
];
