import { useTranslation } from 'react-i18next';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { ButtonBanner } from '../../../Common/ButtonBanner/ButtonBanner';
import { DemoModal } from '../../../Common/DemoModal/DemoModal';
import { StyledLandingContainer, StyledLandingPageSection } from '../../../LandingPages.styles';
import { StyledBookADemoTitle } from './BookADemo.styles';

export const BookADemo: React.FC = () => {
  const { t } = useTranslation();
  const { isDesktop } = useDeviceDetect();

  return (
    <StyledLandingPageSection>
      <StyledLandingContainer $size={'md'}>
        <ButtonBanner
          $title1={
            <StyledBookADemoTitle $visualSizeLevel={3} level={2} $align="center">
              {t('landing_page.enterprise.book_demo.title')}
            </StyledBookADemoTitle>
          }
          $content={t('landing_page.enterprise.book_demo.content')}
          $button={
            <DemoModal
              $btnText={t('landing_page.enterprise.book_demo.btn')}
              $refName="contact-us-demo"
              $btnWidth={isDesktop ? 'fit-content' : '100%'}
            />
          }
        />
      </StyledLandingContainer>
    </StyledLandingPageSection>
  );
};
