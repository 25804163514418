import React from 'react';
import { TextInsideImageProps } from './TextInsideImage.consts';
import { StyledImageContainer, StyledOverlayTextContainer } from './TextInsideImage.styles';
import { StyledGetStartedText } from '../ButtonBanner/ButtonBanner.styles';

export const TextInsideImage: React.FC<TextInsideImageProps> = ({
  $width,
  children,
  $imgSrc,
  $txt: txt,
  $className: className,
}) => {
  return (
    <StyledImageContainer $width={$width} $imgSrc={$imgSrc} className={className}>
      {children}
      <StyledOverlayTextContainer>
        <StyledGetStartedText fontSize={'14px'}>{txt}</StyledGetStartedText>
      </StyledOverlayTextContainer>
    </StyledImageContainer>
  );
};
