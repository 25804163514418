import styled from 'styled-components';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledCol, StyledRowOffset } from '@/shared/styles';

export const StyledImportanceCol = styled(StyledCol)`
  gap: 32px;
`;

export const StyledImportance = styled(StyledRowOffset)`
  display: grid;

  ${() => {
    const { isDesktop, isWideDesktop } = useDeviceDetect();
    return `grid-template-columns: ${
      isDesktop ? '2fr 3fr' : isWideDesktop ? 'repeat(2, 1fr)' : '1fr'
    };`;
  }}
`;
