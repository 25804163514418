import styled from 'styled-components';
import { isMobileOnly } from 'react-device-detect';
import { StyledCol, StyledMain } from '@/shared/styles';

export const StyledTabsSection = styled(StyledMain)`
  border: 2px solid ${(props) => props.theme.backgroundColor};
  ${isMobileOnly && `padding: 0px;`}
`;

export const StyledTopSection = styled(StyledCol)`
  background: ${(props) => props.theme.marginsColor};
  padding: 60px;
`;

export const StyledTab = styled.span`
  color: ${(props) => props.theme.textColor};
  font-size: 16px;
  font-weight: 600;
`;
