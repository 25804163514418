import React, { useEffect } from 'react';
import { PolicySection } from './PolicySection/PolicySection';
import { StyledPolicyContainer } from './Policies.styles';
import { PolicyContent } from './PolicySection/PolicySection.consts';
import { usePolicies } from '@/hooks/Policies/usePolicies';
import { PolicyType } from '@/hooks/Policies/Policies.consts';
import { useTranslation } from 'react-i18next';

export const TermsAndConditions: React.FC = () => {
  const { i18n } = useTranslation();
  const { getPolicy, policyData } = usePolicies();

  useEffect(() => {
    getPolicy({ params: { type: PolicyType.TERMS_AND_CONDITIONS } });
  }, [getPolicy, i18n.language]);

  return (
    policyData && (
      <StyledPolicyContainer>
        <PolicySection title={policyData.title} $flex="center" />
        {policyData.content.map((item: PolicyContent) => (
          <PolicySection
            key={item.title}
            title={item.title}
            content={item.sections}
            titleLevel={2}
          />
        ))}
      </StyledPolicyContainer>
    )
  );
};
