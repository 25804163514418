import React from 'react';
import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledCol } from '@/shared/styles';
import { ButtonBannerProps } from './ButtonBanner.consts';
import { StyledButtonBanner, StyledGetStartedText } from './ButtonBanner.styles';
import { StyledLandingContainer } from '../../LandingPages.styles';

export const ButtonBanner: React.FC<ButtonBannerProps> = ({
  $title1,
  $title2,
  $content,
  $button,
  $dark,
}: ButtonBannerProps) => {
  const { isMobile } = useDeviceDetect();

  return (
    <StyledButtonBanner $dark={$dark}>
      <StyledLandingContainer $size={'sm'}>
        <StyledCol $gap={24} $align={isMobile ? 'stretch' : 'center'}>
          <StyledCol $gap={16} $align="center">
            {React.cloneElement($title1, { level: 2 })}
            {$title2 && React.cloneElement($title2, { level: 3 })}
            <StyledGetStartedText textAlign={'center'}>{$content}</StyledGetStartedText>
          </StyledCol>
          {$button}
        </StyledCol>
      </StyledLandingContainer>
    </StyledButtonBanner>
  );
};
