import i18n from '@/services/localization';
import { isValidLanguage } from '@/shared/utils';
import { defaultLanguage } from '@/stores/LangStore/LangStore.consts';
import { useNavigate } from 'react-router-dom';

export const useU1Navigation = () => {
  const navigateTo = useNavigate();

  const navigate = (path: string, language?: string, replace = false) => {
    navigateTo(buildLangPath(path, language), { replace });
  };

  const buildLangPath = (relativePath: string, language?: string) => {
    if (language) {
      language = language === defaultLanguage ? '' : language;
    } else {
      language = i18n.language && i18n.language !== defaultLanguage ? i18n.language : '';
    }
    return [
      '/',
      language, // language detection
      relativePath,
    ]
      .join('/')
      .replace(/\/+/g, '/');
  };

  const removeLanguageFromPath = (pathname: string): string => {
    const removeDoubleSlashesRegExp = /^\/|\/$/g;
    const pathLangArray: string[] = pathname.replace(removeDoubleSlashesRegExp, '').split('/');
    if (isValidLanguage(pathLangArray[0])) {
      pathLangArray.shift();
    }

    return pathLangArray.join('/');
  };

  return { navigate, buildLangPath, removeLanguageFromPath };
};
