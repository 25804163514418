import { StyledCol, StyledTitle } from '@/shared/styles';
import { PolicySection } from '@/shared/pages/Policies/PolicySection/PolicySection';
import { PolicyContent } from '@/shared/pages/Policies/PolicySection/PolicySection.consts';
import { TabsContentProps } from '../../TabsInfo.consts';
import { Icon } from '@/shared/components/Icon';
import { linkIcon } from '@/assets/images/icons';
import { StyledPinkGradientLink } from '@/shared/components/Link/Links.styles';

export const PlatformInfo: React.FC<TabsContentProps> = ({ info, pdfName }: TabsContentProps) => {
  return (
    <StyledCol $gap={40}>
      <StyledPinkGradientLink href={info.pdfLink} target="_blank" rel="noreferrer">
        <Icon $src={linkIcon} /> {pdfName}
      </StyledPinkGradientLink>
      {info.title && <StyledTitle level={2}>{info.title}</StyledTitle>}
      {info.content?.map((comp: PolicyContent, i: number) => (
        <StyledCol key={i} $gap={20}>
          <PolicySection title={comp.title} titleLevel={3} content={comp.sections} />
        </StyledCol>
      ))}
    </StyledCol>
  );
};
