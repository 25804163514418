import useDeviceDetect from '@/hooks/useDeviceDetect';
import { StyledAdjustableRow } from '@/shared/styles';
import styled from 'styled-components';

export const StyledBanner = styled.img`
  border-radius: 24px;
  width: 100%;
`;

export const StyledTextSection = styled.span`
  color: ${(props) => props.theme.textColor};
  font-size: 18px;
  font-weight: 400;

  ${() => {
    const { isDesktop, isMobile } = useDeviceDetect();

    return `
    ${isDesktop && `width: 50%;`}
    ${isMobile && `text-align: center;`}`;
  }}
`;

export const StyledText = styled(StyledAdjustableRow)`
  display: grid;
  gap: 20px;
  justify-content: space-between;

  ${() => {
    const { isMobile, isTablet } = useDeviceDetect();

    return `
      grid-template-columns: repeat(${isMobile || isTablet ? '1' : '2'}, 1fr);`;
  }}
`;
