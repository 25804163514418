import styled from 'styled-components';
import { StyledCol } from '@/shared/styles';
import { useLangStore } from '@/stores/LangStore/LangStore';
import useDeviceDetect from '@/hooks/useDeviceDetect';

export const StyledMainIntro = styled(StyledCol)`
  ${() => {
    const { isMobile, isTablet, isDesktop, isWideDesktop } = useDeviceDetect();
    const isRtl = useLangStore((state) => state.isRtl());

    return `
      flex-direction: ${isMobile || isTablet ? 'column' : 'row'};
      align-items: ${isMobile || isTablet ? 'flex-start' : 'center'};
      
      img {
        ${
          isDesktop || isWideDesktop
            ? 'width: 50vw'
            : isMobile
            ? 'width: calc(100% + 16px)'
            : 'width: calc(100% + 24px)'
        };
        height: auto;
        max-width: ${isDesktop ? '510px' : isWideDesktop ? '810px' : 'none'};
        align-self: ${isDesktop || isWideDesktop ? 'flex-start' : 'flex-end'};
        object-fit: contain;
        object-position: top;
        margin-inline-end: ${isDesktop || isWideDesktop ? '0' : isMobile ? '-16px' : '-24px'};

        ${
          (isDesktop || isWideDesktop) &&
          `
            position: absolute;
            top: 0;
            right: 0;
            left: ${isRtl ? '0' : 'auto'};
            right: ${isRtl ? 'auto' : '0'};
            transform: ${isRtl ? 'rotateY(180deg)' : 'none'};
          `
        }
      }
    `;
  }}
`;

export const StyledIntroContent = styled.div`
  ${() => {
    const { isDesktop, isWideDesktop } = useDeviceDetect();

    return `
      padding-top: 40px;
      padding-bottom: ${isDesktop || isWideDesktop ? '40px' : '0'};
    `;
  }}
`;

export const StyledFakeIntroImg = styled.div`
  ${() => {
    const { isDesktop, isWideDesktop } = useDeviceDetect();

    return `
      width: 50vw;
      max-width: ${isDesktop ? '510px' : isWideDesktop ? '810px' : '100%'};
      margin-inline-end: ${isDesktop || isWideDesktop ? '-24px' : '-16px'};
      flex: 0 0 auto;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 0;
        padding-top: 79.5%;
      }
    `;
  }}
`;
