import { StyledCol, StyledImg, StyledText, StyledTitle } from '@/shared/styles';
import { U1Logo } from '../../U1Logo';
import { U1Alert } from '../../U1Alert';
import { emailIcon } from '@/assets/images/icons';
import { useTranslation } from 'react-i18next';

export const EmailSent: React.FC = () => {
  const { t } = useTranslation();
  return (
    <StyledCol $gap={32} $align="center">
      <U1Logo $size={42} />
      <U1Alert>
        <StyledCol $gap={8}>
          <StyledTitle level={2}>{t('form.email_sent')}</StyledTitle>
          <StyledText fontSize="16px" fontWeight={100} textAlign="center">
            {t('form.thank_you_for_contacting_us')}
          </StyledText>
        </StyledCol>
      </U1Alert>
      <StyledImg src={emailIcon} $size={160} alt="" />
    </StyledCol>
  );
};
