import { PAGES } from '../consts';
import { Icon } from './Icon';
import { useTranslation } from 'react-i18next';
import { StyledLink } from '../styles';
import { useU1Navigation } from '@/hooks/useU1Navigation';
import { u1Logo } from '@/assets/images/landingPage/icons';
import { IconProps } from '../props';

export const U1LandingPageLogo: React.FC<IconProps> = ({ $maxWidth, $height }: IconProps) => {
  const { t } = useTranslation();
  const { buildLangPath } = useU1Navigation();

  return (
    <StyledLink href={buildLangPath(PAGES.SHARED.DEFAULT)} aria-label={t('landing_page.logo')}>
      <Icon $maxWidth={$maxWidth} $height={$height} $src={u1Logo} $alt={t('landing_page.logo')} />
    </StyledLink>
  );
};
